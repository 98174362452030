.rfi .listitem.ignite {
  background-color: #5c6ae0;
  border-radius: 3px;
  color: #fff !important;
  display: flex;
  gap: var(--spacing-100);
  justify-content: flex-start;
  margin: -5rem 0 var(--spacing-400) 0;
  padding: var(--spacing-300) var(--spacing-200);
  width: 100%;
}

.rfi .listitem.ignite > div {
  flex: none;
}

.rfi .listitem.ignite .icon {
  color: #fff !important;
  cursor: pointer;
  font-size: 15px;
  min-width: auto !important;
  width: auto !important;
}

.rfi .listitem.ignite .textlink {
  padding: 0 var(--spacing-200);
  text-decoration: underline;
}

.rfi .listitem.ignite .textlink:hover {
  background-color: #5c6ae0;
}
