@import './base.module';

.search-overlay {
  background-color: #9b9b9d;
  margin: 0 0  0 75px;
}

.search-overlay .body {
  max-height: calc(100% - 75px) !important;
  overflow-y: scroll !important;
}

.search-overlay .content {
  overflow: auto !important;
}

.search-overlay .content .search-row {
  opacity: 1;
  transition: opacity 0.2s;
  &.hidden { opacity: 0; }
}

.search-overlay .content .top {
  align-items: center;
  background-color: #fff !important;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  .icon { color: #1a1b33 !important; }
}

.search-overlay .title {
  color: #1a1b33 !important;
  display: flex !important;
  font-size: 3.2rem !important;
  font-weight: 700 !important;
  justify-content: space-between !important;
}

.search-overlay .title .label {
  align-items: center;
  display: flex;
}

.search-overlay .title .close {
  color: #1a1b33 !important;
  cursor: pointer;
  font-size: 3.2rem !important;
  font-weight: 700 !important;
}

.search-overlay .type {
  align-items: center;
  display: flex;
}

.search-overlay .type .dropdown {
  height: 48px;
  span:not(.icon) { text-transform: capitalize !important; }
  &:hover { background-color: rgba(152, 152, 152, 0.2); }
}

.search-overlay .type .dropdown button {
  height: 100% !important;
  padding-right: 30px !important;
}

.search-overlay .type .dropdown ~ .icon {
  pointer-events: none !important;
  right: 30px !important;
  top: 3px !important;
}

.search-overlay .type .dropdown button span {
  color: #1a1b33 !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
}

.search-overlay .date {
  align-items: center;
  display: flex;
}

.search-overlay .date .dropdown {
  color: #1a1b33 !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.search-overlay .search-container .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.search-overlay .search-container .actions .textlimit.error {
  color: #c61b1b;
}

.search-overlay .search {
  background-color: #1a1b33 !important;
  border-radius: 3px !important;
  color: #fff !important;
  margin-left: 10px !important;

  &.save { background-color: #5c6ae0 !important; }
}

.search-overlay .tabs {
  color: #1a1b33 !important;
}

.search-overlay .tabs > div {
  background-color: transparent !important;
}

.search-overlay .tabs .tab {
  color: #1a1b33 !important;
}

.search-overlay .tabs .tab .tab-content {
  color: #1a1b33 !important;
}

.search-overlay .searches {
  height: 250px !important;
  max-height: 250px !important;
  overflow: hidden;
  padding: 10px 0 0 !important;
}

.search-overlay .searches .item {
  color: #1a1b33 !important;
  text-transform: capitalize;

  > div > div { padding: 10px !important; }
}

.search-overlay .searches.saved .item {
  color: #1a1b33 !important;
  text-transform: capitalize;

  > div > div { padding: 14px 10px 10px 50px !important; }
}

.search-overlay .searches .item div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-overlay .fixed-search-row {
  left: 0;
  margin: auto;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 75px;
  transition: opacity 0.2s;
  width: 100%;
  z-index: -1;
}

.search-overlay .fixed-search-row.show {
  margin: 0 0 0 75px;
  opacity: 1;
  top: 0;
  width: 95%;
  z-index: 300;
}

.search-overlay .fixed-search-row > div {
  background-color: #e6e7e8;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, .12);
}

.search-overlay .fixed-search-row .top {
  justify-content: flex-start !important;
  margin: 0 46px 10px;
  width: auto !important;

  > div:first-of-type { flex-grow: 2; }
  > div:first-of-type > div:last-of-type { flex-grow: 2; }
}

.search-overlay .fixed-search-row .top > div {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.search-overlay .fixed-search-row .bottom {
  justify-content: flex-start !important;
  margin: 0 46px 10px;
  width: auto !important;
}

.search-overlay .fixed-search-row .bottom .applied {
  align-items: center;
  color: #5c6ae0;
  display: flex;
  flex-direction: row;
  font-weight: 700;
}

.search-overlay .fixed-search-row .input {
  margin-left: 35px;
}

.search-overlay .fixed-search-row .input input::placeholder {
  color: #c3c3c7 !important;
  font-style: italic;
  padding: 0 0 0 1px;
}

.search-overlay .overlay {
  display: none !important;
}

.search:disabled,
.search.save:disabled {
  background-color: #9b9b9d !important;
}
