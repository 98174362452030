$background-fade: rgba(255, 255, 255, .54);

.tags {
  padding: 5px;
}

.tags .button {
  color: #8e96b1 !important;
  text-transform: uppercase;
  .icon { max-width: 20px !important; }
}

.tags .button span {
  color: #8e96b1 !important;
}

.tags .chips {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
}

.tags .chip {
  background-color: #5c6ae0 !important;
  margin: 10px 0 0 !important;
  text-transform: capitalize;
  &.disabled { background-color: #a1a9ed !important; }
}

.tags .chip.disabled {
  background-color: #a1a9ed !important;
  .avatar { background-color: #a1a9ed !important; }
}

.tags .chip .icon {
  color: #fff !important;
}

.tags .chip > span {
  color: #fff !important;
}

.tags .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.overlay {
  background-color: $background-fade !important;
}
