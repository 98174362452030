.pietable {
  width: 100%;
}

.pietable.centered {
  align-items: center;
  display: flex;
}

.pietable .chart {
  display: flex;
  padding: 0;
  width: 100%;
}

.pietable .pie {
  width: 30%;
}

.pietable .table {
  width: 70%;
}

.pietable.centered .table > div {
  align-items: center;
  display: flex;
}

.pietable.transparent .pie {
  width: 50%;
}
