@import './base.module';

.channel .chart {
  margin: 0 0 0 75px;
}

.channel .chart ul li {
  cursor: pointer;
}

.channel .chart ul span {
  color: #5c6ae0 !important;
  text-decoration: underline;
}

.channel .chart ul li:hover span {
  opacity: .6;
}

.channel .chart svg > g > g > g:hover path {
  cursor: pointer;
  fill: #5c6ae0;
}

.channel .server .table {
  justify-content: space-between;
}


.row .label {
  color: #1a1b33;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 10px 0;
}

.row .label.changed::after {
  color: #5c6ae0;
  content: '*';
}

.row .button {
  border: 1px solid #303137 !important;
  border-radius: 5px !important;
  color: #303137 !important;
  height: auto !important;
  line-height: inherit !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  padding: 2px 0 !important;
}

.row .button span {
  letter-spacing: .5px !important;
  text-transform: none !important;
}

.row .button:hover,
.row .button.selected {
  background-color: #5c6ae0 !important;
  border: 1px solid #5c6ae0 !important;
  color: #fff !important;
}

