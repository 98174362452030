.thread {
  align-items: center;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50px;
  transition: width .5s;
  width: 100%;

  > div { width: 100%; }
}

.thread .header {
  margin: 0 100px 35px 0;
}

.thread .title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.thread .title .h1 {
  max-width: 700px;
}

.thread .title .icon {
  color: #000 !important;
  margin: 0 0 0 -30px;
  width: 30px;
}

.thread .original .icon {
  top: 6px;
}

.thread .original .a .icon {
  color: #5c6ae0 !important;
}

.thread .column {
  height: 100%;
  overflow: auto;

  &.op { padding-left: 50px !important; }
}

.thread .op .no-post.disabled {
  align-items: center;
  display: flex;
  margin-right: 100px;
  min-height: 150px;
  position: relative;
}

.thread .op .no-post .empty {
  text-align: center;
  width: 100%;
}

.thread .op .no-post .empty > div {
  border: 1px solid #9b9b9d;
  color: #9b9b9d;
  margin: 0 auto;
  padding: 15px 35px;
  width: 225px;
}

.thread .op .no-post .empty a {
  color: #5c6ae0 !important;
  text-decoration: none !important;
}

.thread .comment-section {
  height: 100%;
  overflow: hidden;
  padding-bottom: 150px;
}

.thread .comment-section .header {
  margin: 22px 0 47px;
}

.thread .comments {
  overflow: auto;
}

.thread .comments.disabled {
  align-items: center;
  display: flex;
  min-height: 150px;
  pointer-events: none;
  position: relative;
}

.thread .comments .empty {
  text-align: center;
  width: 100%;
}

.thread .comments .empty > div {
  border: 1px solid #9b9b9d;
  color: #9b9b9d;
  margin: 0 auto;
  padding: 15px 35px;
  width: 225px;
}
