@import './base.module';

.admin [mode='indeterminate'] {
  margin: 0 10px 0 0;
}

.admin .search-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  .button { margin-left: 24px; }
}

.admin .card {
  overflow-wrap: break-word;
}

.admin .card .header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.admin ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.admin ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.admin .table .value {
  flex-grow: 1;
}

.admin .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  span { color: #fff !important; }
  div { height: auto !important; }
}

.admin .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.admin .help {
  font-size: 1.6rem !important;
  padding: 0 5px;
  vertical-align: middle !important;
}

.admin .header .title .help {
  font-size: 1.6rem !important;
  height: inherit !important;
  left: inherit !important;
  margin: 0 !important;
  padding: 0 5px;
  top: inherit !important;
  vertical-align: middle !important;
}

.admin pre {
  max-height: 200px;
  overflow: auto;
  white-space: pre-wrap !important;
  width: 100%;
}

.admin .form {
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #e6e7e8 !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 6px rgba(150, 150, 150, .12), 0 1px 4px rgba(150, 150, 150, .24) !important;
  display: flex;
  flex-grow: 1;
  padding: 0 10px !important;
}

.admin .form .icon.search {
  color: #5c6ae0 !important;
}

.admin .form .icon.close {
  padding: 0 10px;
  position: relative !important;
}

.admin .form .input {
  border: 0;
  height: auto !important;
  overflow: hidden;
  padding: 0 650px 0 35px !important;
  transition: all .2s ease;
  width: auto !important;
}

.admin .form .input input {
  font-size: 2rem !important;
  padding: 10px 0 !important;
}

.admin .form .input input::placeholder {
  color: #c3c3c7 !important;
  font-style: italic;
  padding: 0 0 0 1px;
}

.admin .table {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.admin .table .h4 {
  min-width: 250px;
}
