@keyframes btnWiggle {
  0% {-webkit-transform: rotate(0deg);}
  2% {-webkit-transform: rotate(-2deg);}
  3.5% {-webkit-transform: rotate(2deg);}
  5% {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(0deg);}
}

@keyframes btnPulse {
  10% { box-shadow: 0 0 0 8px rgba(#5c6ae0, .3); }
  20% { box-shadow: 0 0 0 0 rgba(#5c6ae0, 0); }
  30% { box-shadow: 0 0 0 8px rgba(#5c6ae0, .3); }
  40% { box-shadow: 0 0 0 0 rgba(#5c6ae0, 0); }
  50% { box-shadow: 0 0 0 8px rgba(#5c6ae0, .3); }
  60% { box-shadow: 0 0 0 0 rgba(#5c6ae0, 0); }
}

.filters {
  padding: 0;
}

.filters .advanced {
  align-items: flex-start;
  flex-direction: column;
}

.filters .advanced .advanced-label {
  color: #1d1f2a;
  font-weight: 700;
  padding: 10px 0;
  text-transform: uppercase;
}

.filters .advanced .advanced-label span {
  color: #1d1f2a !important;
  padding-right: 10px;
}

.filters .advanced .option {
  margin: 0 1em 1em;
}

.filters .advanced .option .container {
  border-radius: 3px !important;
  box-shadow: none !important;
}

.filters button[disabled] {
  cursor: not-allowed !important;
}

.filters button[name='filter.forums']:disabled {
  cursor: progress !important;
}

.filters .chips {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}

.filters .chips .button {
  color: #9b9b9d !important;
}

.filters .chips .apply.button button {
  animation: btnWiggle 2s infinite;
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 25px !important;
  line-height: 25px !important;
  div { height: auto !important; }
}

.filters .chips .apply.button button span {
  color: #fff !important;
  font-family: 'Montserrat' !important;
}

.filters .chips .apply.button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.filters .hide {
  display: none;
}

.filters .items {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.filters .items > .h4 {
  padding: 0 15px 0 0;
}

.filters .option.active button,
.filters .option.active .icon {
  color: #5c6ae0 !important;
}

.filters .refresh {
  float: right;
  font-size: 2.8rem;
  height: unset;
  margin-top: -3px;
  padding: 6px;
  width: unset;
}

.filters .refresh.dirty {
  animation: btnPulse 10s infinite;
  background-color: #5c6ae0;
  border-radius: 50%;
  color: #fff;
}

.filters .refresh.dirty:hover {
  animation: none;
}

.media-filter .buttons button {
  margin: .5rem 0;
}
