.search .find {
  display: flex;
  justify-content: center;
}

.search .header {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 -10px;
  padding: 0 5px 10px;
}

.search .header .icon {
  color: #000 !important;
  margin: -2px 0 0;
  width: 30px !important;
}

.search .header > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.search .safe {
  display: flex;
  padding-left: 2rem;
}

.search .title {
  padding-left: 0 !important;
}

.search .title h1 {
  color: #1a1b33;
  margin: 10px 0;
}

.search .sections {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.search .sections .active {
  border-bottom: 4px solid #5c6ae0 !important;
}

.search .results {
  padding: 0;
  position: relative;
}

.search > div:nth-child(2) {
  padding: 5px 0 0;
}

.alertToast {
  background-color: #f7f7f8 !important;
}

.alertToast .MuiAlert-icon svg {
  fill: #fb8721 !important;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.actions .left {
  left: 0;
  position: absolute !important;
}

.actions button div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
}

.actions .active span {
  color: #5c6ae0 !important;
}

.button button {
  height: 25px !important;
  line-height: 25px !important;
  span { font-family: 'Montserrat' !important; }
}
