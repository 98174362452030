.dashboard {
  margin-top: 32px;
  padding: 0 !important;
}

.dashboard .widgets {
  margin: 10px 0 0;
}

.dashboard .widgets > div {
  height: 100%;
}
