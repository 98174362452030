.invalid {
  align-items: center;
  display: flex;
  height: 100% !important;
  justify-content: center;
  max-width: 450px;
}

.invalid.dark .card {
  background-color: transparent !important;
  box-shadow: none !important;
}

.invalid.dark .h0 {
  color: #fff !important;
}

.invalid.dark .h1 {
  color: #fff !important;
}

.invalid.dark .text {
  color: #fff !important;
}

.invalid .card {
  align-items: center;
  display: flex;
  padding: 0 15px;
}

.invalid .card .icon {
  color: #e6e7e8 !important;
  font-size: 42px !important;
}

.invalid .list {
  padding: 0 15px;
}

.invalid .list li {
  list-style-type: disc;
}

.invalid .list li a {
  color: #5c6ae0 !important;
  display: inline-block;
  padding: 0 !important;
}

.invalid .subtitle b {
  color: #5c6ae0;
  cursor: pointer;
}

.invalid .text {
  padding: 15px;
}

.invalid .text a {
  color: #5c6ae0;
  text-decoration: none;
}

.invalid pre {
  white-space: pre-wrap;
}
