@import './base.module';

.textfilter {
  max-height: 60vh;
  max-width: 750px;
  min-width: 375px;
  overflow-x: hidden;
}

.textfilter.advanced {
  margin: 0;
  min-width: auto;
  padding: 0;
}

.textfilter .advancedfooter {
  display: none;
}

.textfilter.advanced .changed label::after,
.textfilter.advanced .label.changed::after {
  color: #5c6ae0;
  content: '*';
}

.textfilter.stacked.base {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  overflow: visible;
  padding: 0 !important;
}

.textfilter.stacked .label {
  padding: 0;
  white-space: nowrap;
}

.textfilter.stacked .chip > div {
  align-items: center;
  display: flex;
  padding: 0 15px 0 0;
}

.textfilter.stacked .chip > div span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.textfilter.stacked .dropdown {
  background-color: #fff !important;
  font-size: 1.8rem;
}

.textfilter.stacked .multiselect {
  margin: 0 1.5rem;
}

.textfilter.stacked .multiselect hr {
  display: none !important;
}

.textfilter.stacked .multiselect button {
  font-size: 1.6rem;
}

.textfilter.stacked .input > div > div {
  padding: 0 10px 0 0;
}

.textfilter.stacked .toggle {
  margin: 0 1.5rem;
}

.textfilter .checkbox {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 15px 8px 8px;
}

.textfilter .checkbox.inline {
  padding: 0 0 15px;
}

.textfilter .checkbox .boxes {
  display: flex;
  margin: 0 0 0 10px;
}

.textfilter .checkboxes {
  margin: 10px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: top;
}

.textfilter .checkboxes .boxes > div {
  display: flex !important;
}

.textfilter .checkboxes label {
  display: block;
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textfilter .checkboxes fieldset {
  margin: 0 10px 0 0;
}

.textfilter .chip label {
  font-size: 1.6rem !important;
}

.textfilter .chip > div > div >div ~div:nth-child(2) {
  bottom: 8px !important;
  color: #9b9b9d !important;
  font-size: 1.6rem !important;
}

.textfilter .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  margin: 0 0 0 10px;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.action) { text-transform: capitalize !important; }
}

.textfilter .dropdown.inline {
  padding: 0 0 15px;
}

.textfilter .dropdown ~ .action {
  color: #9b9b9d !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 2px !important;
}

.textfilter .dropdown > div > div {
  color: #9b9b9d !important;
  font-size: 1.6rem !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 0 !important;
  top: 5px !important;
}

.textfilter .header {
  color: #5c6ae0;
  font-weight: 700;
  padding: 10px 10px 0;
}

.textfilter .loading {
  padding: 0 0 0 10px;
}

.textfilter .multiselect > div {
  color: #9b9b9d !important;
  font-size: 1.6rem !important;
}

.textfilter .multiselect > div > div > div {
  color: #9b9b9d !important;
  font-size: 1.6rem !important;
}

.textfilter .textInput {
  white-space: nowrap;
}

.textfilter .toggle {
  padding-left: 2rem;
}

.textfilter .toggle.inline {
  padding: 0 0 15px;
}

.textfilter .item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.textfilter .input input {
  font-size: 1.6rem !important;
  font-style: normal !important;
}

.textfilter .input.chip {
  position: relative;
}

.textfilter .input.chip .icon {
  position: absolute !important;
  right: 15px;
  top: 35px;
  &.hide { display: none !important; }
}

.textfilter .range > span {
  margin-left: 10px;
  width: calc(100% - 24px);
}

.textfilter .help {
  align-items: center !important;
  display: flex !important;
}

.textfilter.white {
  background-color: #fff !important;
  border: 1px solid #9b9b9d !important;
  border-radius: 10px !important;
  padding: 0 24px !important;
}

.textfilter .phash {
  padding-top: 5px;
}

.toggle .disabled {
  opacity: 0.25;
  pointer-events: none;
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
}
