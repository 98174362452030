$disable: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;

$shadow: 0 1px 6px rgba(150, 150, 150, .12);

.find {
  margin: 0 2rem;
  perspective: 200px;
  position: relative;
  transform-style: preserve-3d;
  width: calc(100% - 75px - 4rem);
}

.find .fields {
  align-items: center;
  display: flex;
  position: relative;
}

.find .fields .form {
  margin-right: 3.2rem;
  position: relative;
  width: 100%;
}

.find .form {
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #8e96b1;
  border-radius: 4px !important;
  display: flex;
  .hover:hover { color: #000 !important; }
  &.filters { border-radius: 3px 3px 0 0 !important; }
  &.filters .type .dropdown { border-radius: 3px 0 0 !important; }
}

.find .form .icon.expand {
  margin: 0;
  position: relative !important;
}

.find .form .icon.filter {
  position: relative !important;
  &.open { color: #303137 !important; }
  &.filters { color: #5c6ae0 !important; }
}

.find .form .icon.close {
  padding: 0;
  position: relative !important;
}

.find .form .icon.search {
  padding: 0;
  position: relative !important;
}

.find .form .textlimit {
  bottom: -30px;
  color: #9b9b9d;
  position: absolute;
  right: 20px;
}

.find .form .textlimit.error {
  color: #c61b1b;
}

.find .form .input-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 2.4rem;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.find .form .input-container .error {
  background-color: #d32f2f;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .5rem;
  color: #fff;
  padding: .3rem 1rem;
}

.find .form .input-container.focused {
  overflow: visible;
}

.find .form .input-container.focused .input {
  box-shadow: $shadow;
}

.find .form .input-container.focused .suggestions {
  display: block;
}

.find .form .input {
  position: absolute !important;
  top: 0;
  transition: all .2s ease;
}

.find .form .input textarea {
  background-color: #fff !important;
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  overflow: hidden !important;
  padding: 0 125px 10px 35px !important;
}

.find .form .input textarea::placeholder {
  color: #8487a2 !important;
  font-size: 1.4rem !important;
  font-style: italic !important;
  opacity: 1;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.find .form .escape {
  align-items: center;
  display: flex;
  font-size: 1.2rem !important;
  justify-content: space-between;
  line-height: 1.2rem !important;
  padding: 0 10px;
  width: 200px;
}

.find .form .escape .button {
  border: 1px solid #9b9b9d;
  border-radius: 5px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24);
  cursor: pointer;
  display: inline-block;
  height: 10px;
  padding: 5px 10px;
  text-align: center;
  &:hover { background-color: #e6e7e8; }
}

.find .form .suggestions {
  box-shadow: $shadow;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.find .header .h2 {
  color: #000;
  padding: 0 0 0 30px;
}

.find .header .icon {
  color: #000 !important;
  width: 30px !important;
}

.find .header {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
  margin: 150px 0 10px;
}

.find .icon {
  background: transparent;
  cursor: pointer;
  position: absolute !important;
  z-index: 1;
}

.find.inline .header {
  display: none !important;
}

.actions .accept {
  color: #5c6ae0 !important;
  font-family: 'Montserrat';
}

.menu {
  padding: 0 !important;
}

.item {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  line-height: 36px !important;
  min-height: 36px !important;
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.item.disabled {
  color: #000 !important;
  font-style: italic;
  text-transform: none;
}

.item .icon {
  height: 16px !important;
  left: 5px;
  position: relative;
  width: 16px !important;
}

.item .icon.right {
  left: unset !important;
  top: -3px !important;
}

.item .icon.check {
  left: auto !important;
  margin: 8px 15px 0 0 !important;
}

.textbox textarea {
  border: 1px solid #e6e7e8 !important;
  border-radius: 5px !important;
}
