@import '../base.module';

.media {
  padding: 15px;
  width: 1050px;
}

.media .image {
  cursor: pointer;
  display: block;
  height: 400px;
  object-fit: contain;
  width: 100%;
}

.media a {
  color: #5c6ae0;
}

.media .counts {
  display: flex;
  flex-direction: column;
}

.media .h1 {
  font-weight: 400 !important;
}

.media .header {
  align-items: center;
  display: flex;
}

.media .header .icon {
  font-size: 1.8rem !important;
  margin: -5px 0 0 -7px !important;
}

.media .invalid {
  height: 75vh;
}

.media .description > p {
  margin-top: 0;
}

.media .section {
  background-color: #fff;
  border: 1px solid #9b9b9d;
  display: flex;
  margin: 5px 0 0;
  overflow: hidden;
  padding: 15px;
}

.media .section.attacks {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  margin: 0;
  overflow: visible;
}

.media .section.classifications {
  height: 150px;
}

.media .section.text,
.media .section.posts {
  height: 350px;
}

.media .title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
}

.media .title .h1 {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.MuiTableContainer-root) {
  overflow-x: auto !important;
}
