.base {
  margin: 0 0 35px; /* account for fixed height footer */
  max-height: 60vh;
  overflow: auto;
  padding: 15px;
}

.base.inline {
  margin: 0;
  min-width: auto;
  padding: 0;
}

.base .actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.base .actions .left {
  left: 15px;
  position: absolute !important;
}

.base .actions button div {
  color: #8e96b1 !important;
  font-family: 'Montserrat';
}

.base .actions .active {
  color: #5c6ae0 !important;
}

.base .button {
  box-shadow: none !important;
}

.base .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 25px !important;
  line-height: 25px !important;
}

.base .button button span {
  color: #fff !important;
}

.base .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.base .button button > div > div {
  font-family: 'Montserrat' !important;
  height: 25px !important;
}

.base .footer {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e6e7e8;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 5px;
  position: absolute !important;
  width: 100%;
  z-index: 100;
}

.base .footer.advanced {
  display: none;
}

.base .footer .error {
  color: #eb002b;
}

.base .form {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.base .form .hr {
  margin: 35px 0 0;
}
