.reports {
  background-color: #fff;
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  margin: 0;
  min-height: 100vh;
  padding: 35px 0;
}

@media print {
  .reports {
    box-shadow: none !important;
    >div { display: block !important; }
  }
}
