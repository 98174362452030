.accept {
  color: #5c6ae0 !important;
}

.navigation .brand {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 10px 0 0 -5px;
  width: 100%;
}

.navigation .h1 {
  color: #fff;
}

.navigation .item {
  border-radius: 2px;
  padding: 5px 0;
}

.navigation .item.active {
  background-color: #f6f7fd;
  border-left: 2px solid #5c6ae0;
  color: #5c6ae0;
}

.navigation .icon {
  margin: 0;
  width: 20px;
}

.navigation .icon-flame svg {
  cursor: pointer;
  fill: #fb8521;
  height: 24px;
  width: 8px;
}

.navigation .icon-logo svg {
  cursor: pointer;
  fill: #2a2c3c;
  height: 24px;
  width: auto;
}

.navigation .text {
  font-size: 1.2rem;
}

.navigation .text span,
.navigation .icon path {
  color: #2a2c3c;
  fill: #7a84a1;
  text-align: left;
}

.navigation .item:hover .icon path {
  fill: #7a84a1;
}

.navigation .active .text span,
.navigation .active .icon path {
  color: #5c6ae0;
  fill: #5c6ae0;
  font-weight: 600;
}

.navigation .footer {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  color: #606580;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  gap: calc(3 * var(--spacing-1000));
  justify-content: center;
  line-height: 1.43;
  padding: 15px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.navigation .footer img {
  min-width: 100%;
  width: 100%;
}

.navigation .footer .label {
  padding: var(--spacing-200) 0;
}

.navigation .footer .ignite {
  margin-top: 15px;
  padding: var(--spacing-300);
}


@media print {
  .navigation {
    display: none;
  }
}
