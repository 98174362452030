.suggestions {
  background: #fff;
  min-height: 3.5rem;
}

.suggestions .icon {
  font-size: 4rem;
  margin: -5px 5px 0 0;
}

.suggestions .icon.active {
  color: #5c6ae0;
}

.suggestions .icon.search {
  font-size: 2rem;
  margin: 5px 12px;
}

.suggestions .listitem.ignite {
  background-color: #5c6ae0;
  color: #fff !important;
}

.suggestions .listitem.ignite span {
  font-weight: bold;
}

.suggestions .listitem.ignite .icon {
  color: #fff !important;
}

.suggestions .subheader {
  font-weight: bold;
}
