.hero .chart {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  text-align: center;
}

.hero .chart.narrow {
  padding: 0;
}

.hero .chart .count {
  padding-top: 15px !important;
}

.hero .chart .h0 {
  cursor: pointer;
  font-size: 3.6rem !important;
}

.hero .chart .h0[disabled] {
  cursor: no-drop;
}

.hero .chart:not(.disabled) .h0:hover {
  opacity: 0.6;
}

.hero .chart .cap {
  color: #9b9b9d;
  font-size: 1.4rem;
  padding: 5px 0 10px;
}

.hero .chart .spark {
  max-width: 150px;
}

.hero .chart .spark path {
  stroke: #5c6ae0;
}

.hero .disabled * {
  cursor: not-allowed !important;
}
