.basic-pagination {
  width: 100%;
}

.basic-pagination .actions {
  position: relative;
  text-align: right;
  > div { display: inline-block; }
}

.basic-pagination .actions .link {
  color: #1a1b33;
  font-size: 1.6rem;
  padding: 5px 10px;
  text-transform: uppercase;
}

.basic-pagination .actions .link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.basic-pagination .actions .link.disabled {
  opacity: .4;
  pointer-events: none;
}

.basic-pagination .actions .line::after {
  border: 1px solid #1a1b33;
  content: '';
  height: calc(100% - 10px);
  position: absolute;
  top: 5px;
  width: 0;
}
