.media-grid-image {
  cursor: pointer;
  margin: 2px;
  position: relative;
}

.media-grid-image img {
  backface-visibility: hidden;
  display: block;
  max-height: 300px;
  max-width: 400px;
  opacity: 1;
  transition: .5s ease;
}

.media-grid-image .overlay {
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: .5s ease;
  width: 100%;
  > div { height: 100%; }
}

.media-grid-image .overlay .lightbox {
  left: 5px;
  position: absolute;
  top: 5px;
  z-index: 100;
}

.media-grid-image:hover img {
  opacity: 0.3;
}

.media-grid-image:hover .overlay {
  opacity: 1;
}
