@import '../base.module';

.credentials .card {
  position: relative;
}

.credentials .card .star {
  position: absolute !important;
  right: 20px !important;
  top: 20px !important;
}

.credentials .card .launch span {
  font-size: 16px !important;
}

.credentials .card .accordiondetails {
  margin: 0;
  padding: 0;
  width: 100%;
}

.credentials .card .accordionrow {
  padding-bottom: 20px;
}

.credentials .card .accordionsummary {
  margin: 0;
  padding: 0;
}

.credentials .card .detailsgrid {
  width: 100% !important;
  word-break: break-word;
}

.credentials .card .detailstitle {
  font-weight: bold !important;
  padding: 10px 0 0 5px;
}

.credentials .card .resultaccordion {
  box-shadow: none !important;
  // flex-direction: column !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.credentials .card .resultaccordion::before {
  height: 0;
}

.credentials .card .subtitle {
  text-transform: capitalize;
}

.credentials .card .title {
  padding-bottom: 10px;
}
