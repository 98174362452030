@import './base.module';

.forumsfilter {
  padding: 24px;
}

.forumsfilter.picker {
  max-height: 60vh;
  max-width: 650px;
  overflow: auto;
  width: 650px;
}

.forumsfilter .row:first-of-type .label {
  margin-top: 0;
}

.forumsfilter .row .label {
  color: #1a1b33;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 10px 0;
}

.forumsfilter:not(.picker) .row .label.changed::after {
  color: #5c6ae0;
  content: '*';
}

.forumsfilter .row .button {
  border: 1px solid #303137 !important;
  border-radius: 5px !important;
  color: #303137 !important;
  height: auto !important;
  line-height: inherit !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.forumsfilter .row .button span {
  letter-spacing: .5px !important;
  text-transform: none !important;
}

.forumsfilter .row .button:hover,
.forumsfilter .row .button.selected {
  background-color: #5c6ae0 !important;
  border: 1px solid #5c6ae0 !important;
  color: #fff !important;
}

.forumsfilter .autocomplete {
  position: relative;
  width: 100%;
}

.forumsfilter .autocomplete .icon {
  bottom: 20px;
  position: absolute !important;
  right: 15px;
  &.hide { display: none !important; }
}

.forumsfilter .autocomplete label {
  font-size: 1.6rem !important;
}

.forumsfilter:not(.picker) .autocomplete.changed label::after {
  color: #5c6ae0;
  content: '*';
}

.forumsfilter .row .list {
  max-height: 250px !important;
  overflow-y: auto !important;
  padding: 0 !important;
}

.forumsfilter .row .list label {
  color: #303137 !important;
  padding-left: 52px !important;
}
