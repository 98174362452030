.CSVImport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.CSVImport .uploadContainer {
  height: 12rem;
  width: 95%;
}

.CSVImport .uploadContainer:hover * {
  color: #29b6d6;
}

.CSVImport .cloudIcon {
  color: #999;
  font-size: 5rem; 
}

.CSVImport .description {
  color: #999;
  font-size: 1.25rem;
  font-weight: 550;
  padding-top: 6px;
}

.CSVImport .error {
  color: #d43f3f !important;
}

.CSVImport .inputFile {
  align-items: center;
  border: 2px dashed #999;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.CSVImport .inputFileDragging {
  border: 2px dashed #29b6d6;
}

.CSVImport .inputFile:hover {
  border: 2px dashed #29b6d6;
}

.CSVImport .inputFileButton {
  display: none;
}

.CSVImport legend {
  color: #999;
  font-size: 1.25rem;
  font-weight: 550;
  padding: 0 4px;
}

.CSVImport .title {
  font-size: 1.6rem;
  padding: 8px 0 12px;
}
