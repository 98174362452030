.media-grid,
.media-grid a {
  padding: 0 !important;
}

.media-grid img {
  object-fit: cover;
}

.fluid {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  line-height: 0;
}

.fluid.small {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
}

.fluid img {
  object-fit: contain;
}

:global .react-images__header_button--fullscreen {
  display: none !important;
}

@media (max-width: 1200px) {
  .fluid {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }

  .fluid.small {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
  }
}
@media (max-width: 1000px) {
  .fluid {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

  .fluid.small {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 800px) {
  .fluid {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  .fluid.small {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 400px) {
  .fluid {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }

  .fluid.small {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
