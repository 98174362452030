.card {
  align-items: flex-start;
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 15px;
  position: relative;
  transform: translateZ(0);
  transform-style: preserve-3d;
  transition: scale .2s ease-in-out;
  z-index: 0;
  .subtitle { padding: 10px 0 0; }
}

.card.mini {
  padding: 2.25rem;
  width: 300px;
}

.card.mini:not(.standup) .content {
  max-width: 100%;
  padding: 0;
}

.card.mini .date {
  background-color: #5c6ae0 !important;
  border-radius: 35px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
  color: #fff !important;
  height: 20px;
  overflow: hidden;
  padding: 1px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: capitalize !important;
  top: -10px;
}

.card.mini .date:hover span {
  margin-top: -21px;
}

.card.mini .date span {
  display: block;
  transition: 0.3s;
}

.card.mini .date span:nth-child(2) {
  padding: 20px 0 0;
}

.card.mini .icon {
  color: #5c6ae0 !important;
  font-size: 2.4rem !important;
  padding: 0 5px 0 0;
}

.card.mini .summary {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  min-height: 80px;
  overflow: hidden;
  padding: 0;
}

.card.mini .title {
  align-items: center;
  display: flex;
}

.card.mini .title div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none !important;
}

.drafticon {
  align-self: center;
  font-size: 60px !important;
  justify-content: center;
  left: 40%;
  position: absolute !important;
  z-index: 5;
}

.draft {
  position: relative;
}

.draft::after {
  background: rgba(255, 255, 255, 0.6);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.card:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.card .content {
  padding: 0 15px 0 0;
}

.card .content .category {
  font-size: 1.2rem;
}

.card:not(.standup) .content {
  max-width: 80%;
}

.card .image {
  cursor: pointer;
  height: 135px;
  min-height: 135px;
  min-width: 135px;
  object-fit: cover;
  width: 135px;
  :hover { opacity: .6; };
}

.card .summary {
  overflow-wrap: break-word;
  padding: 10px 0;
}

.card .summary p {
  margin: 0;
  padding: 0 0 35px 25px;
}

.card .ribbon {
  color: #fff;
  margin: -5px -15px 0 0;
  position: absolute;
  right: 0;
  transform: translateZ(0);
  transform-style: preserve-3d;
  z-index: 0;
}

.card .ribbon > div {
  height: auto;
  min-height: 25px;
  padding: 5px 15px;
  position: relative;
}

.card .ribbon > div:nth-child(1) {
  background-color: #5c6ae0;
}

.card .ribbon > div:nth-child(2) {
  background-color: #eb002b;
  height: 100px;
  margin: -30px 8px;
  transform: translateZ(-1em) rotate(45deg);
  transform-style: preserve-3d;
  width: 10px;
  z-index: -1;
}

.card .bookmark {
  bottom: 5px;
  opacity: 0;
  position: absolute;
  right: 5px;
}

.card:hover .bookmark {
  opacity: 1;
}

.card .tags {
  bottom: 10px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card .tags .a {
  text-transform: uppercase;
}

.card.standup .google > div > div > div > *:not(h3) {
  display: block !important;
  font-style: italic;
  margin-bottom: 10px;
  margin-left: 60px;
}

.card.standup .google > div > div > div > span:first-of-type {
  color: #18191c !important;
  font-family: 'Open Sans';
  font-size: 1.4rem !important;
  font-weight: 300;
}

.card.standup .google > div > * {
  height: auto !important;
}

.card.standup img {
  display: none;
}

.card.standup .content {
  width: 100%;
}

.card.standup p {
  margin-left: 65px;
  padding: 0;
}

.card.standup strong {
  color: #000 !important;
}

.card.standup h3 {
  color: #000 !important;
  font-size: 1.2rem;
  margin-block-end: 0;
  margin-left: 65px;
  position: relative;
}

ul[style] {
  list-style-type: none !important;
}

.card.standup h3::before {
  color: #5c6ae0;
  content: '\2022';
  font-size: 1.4rem;
  left: -20px;
  position: absolute;
  top: 0;
}

.card.standup h3 a {
  color: #000 !important;
  display: inline-block !important;
  pointer-events: none !important;
  text-decoration: none !important;
}

.card.standup .tags {
  max-width: 450px;
  overflow: hidden;
  position: absolute;
  right: 15px;
  top: 20px;
}

.card.standup .header {
  display: flex;
}

.card.standup .title {
  align-items: center;
  display: flex;
}

.card.standup .header span {
  margin: 8px 5px;
}

.card.viewed .title {
  color: #9b9b9d !important;
}

.card.viewed:not(.standup) .summary {
  color: #9b9b9d !important;
}

.link {
  text-decoration: none !important;
}

.google ol {
  display: none !important;
}

.google ul {
  display: inline-block;
  margin: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.google ul li {
  margin-left: 48px !important;
}

.google h1 {
  color: #9b9b9d !important;
  font-size: 1.4rem;
  margin: 0 40px;
  position: relative;
}

.google h1 span {
  color: #9b9b9d !important;
  font-size: 1.4rem;
  position: relative;
}

.google h3 {
  padding: 0 !important;
}

.google h3 span,
.google h3 span a {
  margin-left: 0 !important;
  padding: 0 !important;
}

.google p {
  margin: 0 40px !important;
  padding: 0;
}

.google a {
  color: #9b9b9d !important;
  text-decoration: none;
}

.google p {
  margin-bottom: 10px !important;
  margin-left: 47.5px !important;
  text-decoration: none !important;
}

.google p span {
  margin: 0 !important;
}

.google li:last-child {
  display: none;
}

.google li span,
.google p span {
  background-color: transparent !important;
  color: #18191c !important;
  font-family: 'Open Sans';
  font-size: 0.9rem !important;
  font-weight: 300;
  margin: 0 0 0 10px !important;
  text-decoration: none;
  text-overflow: ellipsis;
}

.google ul li {
  color: #000 !important;
  font-family: 'Open Sans';
  font-size: 0.9rem !important;
  margin: 0 0 10px 60px !important;
  text-decoration: none !important;
  text-overflow: ellipsis;
}

.google.standup ul li {
  color: #000 !important;
  font-size: 1.4rem !important;
  margin-bottom: 10px;
  text-decoration: none !important;
  text-overflow: ellipsis;
}

.google.standup .summary {
  margin: 5px 0 0 -30px;
}

.google ul li::before {
  color: #5c6ae0;
  content: '';
  display: inline-block;
  font-weight: bold;
  margin-left: -1em;
  width: 1em;
}

.google ul li span h1 {
  margin: 0 auto;
}

.google ul {
  color: initial !important;
  text-decoration: none !important;
}

.google ul li span {
  color: #000 !important;
  margin: 0 auto !important;
  padding: 0 !important;
  text-decoration: none !important;
}

.google li p span {
  color: #000 !important;
  margin: 0 auto !important;
}

.google li {
  padding: 0;
}

.google h5 {
  background-color: transparent;
}

.card.standup .google h3 {
  margin-bottom: 10px;
}

.card.standup .google h3 span {
  font-size: 0.75rem;
}

.card.standup .google h3 span a {
  color: #000 !important;
  display: inline-block !important;
  font-size: 1.4rem;
  font-weight: 400 !important;
  margin: 0 0 0 -5px !important;
  pointer-events: none !important;
  position: relative;
  text-decoration: none !important;
}

.card.standup .google p,
.card.standup .google ul,
.card.standup .google li {
  border-color: #fff !important;
}

.button {
  background-color: #5c6ae0 !important;
  position: relative;
  z-index: 10000 !important;
}

// prevent annoying css border from docs css
.google :global *[class^='c'] {
  border-color: #fff !important;
}
