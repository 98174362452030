.containers {
  display: flex;
  flex-direction: column;
}

.containers .sections {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containers .section {
  display: flex;
}
