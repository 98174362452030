.dashboard {
  height: 100%;
  margin: 30px 0 0;
  width: 100%;
}

.dashboard .card {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  position: relative;
  z-index: auto !important;
}

.dashboard .card.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}

.dashboard .card.transparent .content {
  padding: 0 !important;
}

.dashboard .content {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashboard .content.vertical {
  align-items: center;
  flex-direction: column;
}

.dashboard .empty {
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

.dashboard .empty > div {
  border: 1px solid #9b9b9d;
  color: #9b9b9d;
  margin: 0 auto;
  padding: 15px 35px;
  width: 225px;
}

.dashboard .link {
  color: #5c6ae0 !important;
  cursor: pointer;
}

.dashboard .link:hover {
  opacity: 0.6;
}

.dashboard .viewlink {
  font-size: 1.4rem;
  margin: .5rem;
  white-space: nowrap;
}

.dashboard .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
}

.dashboard .header .actions {
  align-items: center;
  display: flex;
  margin-left: 5px;
}

.dashboard .header .help {
  align-items: center;
  display: flex;
  margin: 0 auto 0 0.5rem;
}

.dashboard .header .title {
  color: #000;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
}

.dashboard .header .icon {
  color: #9b9b9d !important;
  font-size: 1.8rem !important;
  font-weight: normal;
  text-transform: none;
}

.dashboard .loader {
  padding: 1.5rem;
}

.dashboard .settings {
  position: absolute !important;
  right: 5px;
  top: 0;
}

.dashboard .reactTooltip {
  overflow-y: hidden !important;
}

.dashboard .subtitle {
  color: #18191c !important;
  padding: 0 0 15px;
}

.dashboard .text {
  font-size: 1.8rem;
  padding: 15px 0 0 15px;
}

.dashboard .text a {
  color: #5c6ae0 !important;
  text-decoration: none;
}

.dashboard .text a:hover {
  opacity: 0.6;
}
