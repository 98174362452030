.intelligence {
  width: 1050px;
}

.intelligence.apt,
.intelligence.knowledgebase,
.intelligence.malware,
.intelligence.topics {
  width: 100%;
}

.intelligence > div {
  justify-content: center;
}

.intelligence .input {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 0 15px;
}

.intelligence .input > div {
  width: 65%;
}
