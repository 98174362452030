.base {
  font-size: 1.6rem;
  max-width: 750px;
}

.base .button {
  border-radius: 5px !important;
  box-shadow: none !important;
}

.base .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 25px !important;
  line-height: 25px !important;
}

.base .button button span {
  color: #fff !important;
}

.base .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.base .button button > div > div {
  font-family: 'Montserrat' !important;
  height: 25px !important;
}

.base .inline {
  align-items: center;
  display: flex;
  height: 35px;
  justify-content: flex-start;
  >div:first-child { padding: 0 15px 0 0; }
}

.base .table {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  >div:first-child { min-width: 200px; }
  >div:nth-child(2) { word-break: break-word; }
  .top { align-self: flex-start; }
}
