@import './base.module';

.search .card .table {
  overflow: scroll !important;
}

.search .card .table tbody::before,
.search .card .table tbody::after {
  display: none;
}

.search .card .table td a {
  display: block;
  margin: -10em;
  padding: 10em;
}
