.infinite {
  position: relative;
}

.infinite .loading {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.infinite .hidden {
  display: none;
}

.infinite .load {
  font-family: 'Montserrat' !important;
  span { color: #9b9b9d !important; }
}

.infinite .load circle {
  opacity: 0 !important;
  stroke: #9b9b9d !important;
}

.infinite .load.loading circle {
  opacity: 1 !important;
}

.infinite .header {
  align-items: center;
  background-color: #f7f5f5;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: row;
  height: 30px;
  position: sticky;
  top: 135px;
  width: 100%;
  z-index: 1;
}

.infinite .header > div {
  border-right: 1px solid #fff;
  font-weight: bold;
}
