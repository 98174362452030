@import './base.module';

.ccmc {
  max-width: 950px;
}

.ccmc .tabs > div:first-of-type {
  background-color: transparent !important;
  button { color: #79797b !important; }
}

.ccmc .tabs button:disabled {
  cursor: no-drop !important;
}

.ccmc .body {
  min-width: 375px;
  overflow: auto;
  padding: 24px;
  text-overflow: ellipsis;
}

.ccmc .body .row {
  padding-bottom: 24px;
}

.ccmc .row .download {
  color: #5c6ae0 !important;
  cursor: pointer !important;
  font-family: 'Open Sans',Arial,sans-serif !important;
  font-size: 14px;
  text-align: end;
  text-decoration: underline;

}

.ccmc .body .row.line {
  border-bottom: 1px solid #9b9b9d;
  margin-bottom: 24px;
}

.ccmc .body .label {
  color: #79797b;
  font-family: 'Rajdhani';
  font-size: 2.4em;
}

.ccmc .body .case {
  color: #9b9b9d;
  display: block;
  font-size: 1.4rem;
  max-width: 300px;
  word-wrap: break-word;
}

.ccmc .body .text {
  background-color: #fff !important;
  border: 1px solid #9b9b9d !important;
  border-radius: 10px !important;
  display: flex !important;
  padding: 0 24px !important;
}

.ccmc .body .text > div {
  font-size: 1.6rem !important;
  height: 36px !important;
  line-height: 13px !important;
}

.ccmc .body .text .close {
  height: 72px;
  padding: 16px;
  width: 72px;
}

.ccmc .body .error {
  color: rgb(244, 67, 54);
  font-size: 12px;
  line-height: 12px;
}

.ccmc .body .dropdown.required > div > div:nth-child(2) {
  color: #5c6ae0 !important;
}

.ccmc .body .dropdown.required ~ .icon {
  color: #5c6ae0 !important;
}

.ccmc .body .filters {
  align-items: center;
}

.ccmc .body .filters .filter {
  align-items: center;
  padding-bottom: 12px;
}

.ccmc .body .filters .add,
.ccmc .body .filters .delete {
  height: 32px !important;
  margin-left: 10px;
}

.ccmc .body .filters .add button,
.ccmc .body .filters .delete button {
  background-color: #5c6ae0 !important;
  height: 32px !important;
  width: 32px !important;
}

.ccmc .body .filters .add button span,
.ccmc .body .filters .delete button span {
  color: #fff !important;
  height: 32px !important;
  line-height: 32px !important;
}

.ccmc .body .horizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.ccmc .body .horizontal > div {
  padding-right: 24px !important;
  white-space: nowrap;
  width: auto !important;
}

.ccmc .body .button-container {
  cursor: default !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 15px !important;
}

.ccmc .body .button-container .button button:enabled {
  background-color: #5c6ae0 !important;
  span { color: #fff !important; }
}

.ccmc .body .request {
  border-top: 1px solid #9b9b9d;
  margin-top: 24px;
  padding-top: 24px;
}

.ccmc .body pre {
  margin: 5px;
  outline: 1px solid #9b9b9d;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ccmc .body .curl {
  font-weight: 600;
  // margin: 10px 24px 0;
  word-break: break-all;
}

//
// .ccmc .tabs .content {
//   padding: 10px 25px;
// }
//
// .ccmc .tabs .content .row:not(:last-child) {
//   border-bottom: 1px solid #e6e7e8;
//   padding: 10px 0;
// }
//
// .ccmc :global table td:last-child {
//   overflow: visible !important;
//   white-space: normal !important;
//   word-break: break-all;
// }
