@import 'base.module';

.selectfilter:not(.advanced) {
  max-width: 650px !important;
}

.selectfilter.inline {
  margin: 0 0 0 30% !important;
}

.selectfilter .selection {
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px;
}

.selectfilter .menu {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
