.stackedbar {
  min-height: 350px;
}

.stackedbar .chart {
  cursor: pointer !important;
  padding: 0;
}

.stackedbar .entry.disabled {
  text-decoration: line-through !important;
}

.stackedbar .legend {
  display: flex;
}

.stackedbar .tooltip {
  background-color: #000;
  color: #fff;
  padding: 5px;
}
