.editor {
  padding: 0 24px 58px;
}

.editor .active {
  color: #5c6ae0;
}

.editor .footer {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e6e7e8;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 5px;
  position: absolute !important;
  width: 100%;
  z-index: 100;
}

.editor .footer .error {
  color: #5c6ae0;
}

.editor .confirm-delete {
  color: #5c6ae0 !important;
  font-family: 'Montserrat';
}

.editor .changed::after {
  color: #5c6ae0;
  content: '*';
  margin: -10px 0 0;
  position: absolute;
}

.editor .text input::placeholder,
.editor .textarea textarea::placeholder {
  color: #c3c3c7 !important;
  font-style: italic;
  padding: 0 0 0 1px;
}

.editor .checkboxes {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 15px 5px 0;
}

.editor .checkboxes:not(.inline) .items {
  -webkit-column-count: 2 !important;
  -moz-column-count: 2 !important;
  column-count: 2 !important;
  max-height: 400px !important;
  overflow: auto !important;
  width: 80% !important;
}

.editor .checkboxes .items > fieldset {
  break-inside: avoid; /* IE 10+ */
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
}

.editor .checkboxes.inline {
  flex-wrap: wrap;
  .items > fieldset { padding: 0 1rem 1rem; }
}

.editor .error {
  color: rgb(244, 67, 54);
  font-size: 12px;
  line-height: 12px;
}

.editor .icon {
  margin: 5px -35px 0 !important;
  position: absolute !important;
}

.editor .label {
  color: #000 !important;
  margin: 0 10px 0 5px !important;
}

.editor .required label {
  color: #5c6ae0 !important;
}

.editor .text {
  margin: -15px 0 0;
}

.editor .text.textarea textarea:nth-child(2) {
  overflow: auto;
  resize: vertical !important;
}

.editor .toggle {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px 0 0;
  .disable-text { width: 100% !important; }
}

.editor .toggle > div {
  align-items: center;
  display: flex;
  padding-right: 10px;
  width: 60% !important;
}

.editor .disable-text > div {
  color: #9b9b9d;
  font-size: 1.6rem !important;
  margin: 5px 0 0 5px;
  width: 50%;
}
