.cloudInfrastructureBucket {
  padding: 0 2.4rem 2.4rem;
}

.cloudInfrastructureBucket .error {
  color: #ffa21a;
}


.cloudInfrastructureBucket .fileList {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem 0 0 1rem;
  width: 300px;
}

.cloudInfrastructureBucket .previewContainer {
  font-size: 1.8rem;
  height: 300px;
  margin: 12px 0;
  overflow: hidden;
  overflow-y: scroll;
}
