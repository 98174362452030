.cvedashboard {
  width: 100%;
}

.cvedashboard .containers {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.cvedashboard .containers .sections {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cvedashboard .containers .section {
  display: flex;
}

.noColor {
  color: #000;
}

.hoverMenu {
  background-color: #fff;
  border-radius: 3px;
  display: none;
  filter: drop-shadow(0 0 0.75rem #d5dbea);
  margin-left: -400px;
  margin-top: -240px;
  padding: 7px 0;
  position: absolute;
  width: 155px;
  z-index: 1000;
}

.hoverRow {
  list-style-type: none;
  padding: 4px 10px;
}

.hoverRow:hover {
  background-color: #eff0fb;
}

.vulnDB {
  font-size: 2rem;
  width: 1em;
}

.launch {
  color: #8e96b1;
  font-size: 22px;
  height: 25px;
  padding: 0 !important;
  text-align: right;
  vertical-align: middle;
  width: 25px !important;
}
