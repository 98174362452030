.tag .actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.tag .actions button span {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
}

.tag .actions .active {
  color: #5c6ae0 !important;
}

.tag .footer {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e6e7e8;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 15px 0 0;
  position: relative !important;
  width: 100%;
  z-index: 100;
}

.tag .footer .error {
  color: #5c6ae0;
}
