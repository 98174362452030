.aggregations {
  padding: 0;
  position: relative;
}

.aggregations.ignored {
  max-height: 100px;
  overflow: hidden;
  .placeholder { zoom: 0.7; }
}

.aggregations .loading {
  position: relative;
}

.aggregations .loading .message {
  align-items: center;
  color: #18191c;
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.aggregations .loading .message.ignored {
  cursor: pointer;
  max-height: 100px !important;
}

.aggregations .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24);
  height: 28px !important;
  left: 21px !important;
  line-height: 28px !important;
  z-index: 2 !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.aggregations .dropdown ~ .icon {
  pointer-events: none !important;
  right: 14px !important;
  z-index: 2 !important;
}

.aggregations .dropdown > div > div {
  color: #9b9b9d !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.aggregations .empty {
  padding: 150px 0 0;
}

.aggregations .options {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.aggregations .table {
  align-items: center;
  display: flex;
  justify-content: space-between;
  >div:nth-child(2) { word-break: break-word; }
}

.aggregations .table > div:first-child {
  flex-grow: 1;
  max-width: 200px;
}

.aggregations .title {
  width: 200px;
}

.aggregations .placeholder {
  position: relative;
}

.aggregations .placeholder .spinner {
  left: 50%;
  position: absolute;
  top: 50%;
}

.aggregations .placeholder svg {
  opacity: 0.1;
}

.aggregations .placeholder.error svg {
  opacity: 0.04 !important;
}
