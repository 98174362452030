.management {
  border-radius: 5px !important;
  overflow: visible;
  padding: 0 0 35px;
}

.management .alerting-sticky {
  background-color: #fcfcfc;
  margin: 0 -3px;
  padding: 1rem;
  position: sticky;
  top: 135px;
  z-index: 90;
}

.management a {
  color: #000 !important;
  text-decoration: none !important;
}

.management .active {
  background-color: #fcfcfc !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  a { color: #000 !important; }
}

.management .actions {
  display: flex;
  justify-content: flex-end;
}

.management .button {
  margin-left: 10px;
  padding: 0;
}

.management .button button {
  background-color: #5c6ae0 !important;
  span { color: #fff !important; }
}

.management .card {
  border-radius: 5px !important;
  margin: 0;
  &.loaded >div:nth-child(2) { box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important; }
}

.management .dialog {
  z-index: auto !important;
}

.management .filters .form {
  align-items: center;
  display: flex;
}

.management .filters .icon {
  color: #5c6ae0 !important;
  cursor: pointer;
  &.close { color: #9b9b9d !important; }
}

.management .filters .icon.close {
  margin: 0 0 0 -50px;
  opacity: 0;
  padding: 0 0 0 10px;
}

.management .filters .icon.close.show {
  opacity: 1;
}

.management .filters .dropdown,
.subscriptions .filters .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.management .filters .dropdown ~ .icon,
.subscriptions .filters .dropdown ~ .icon {
  color: #9b9b9d !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 10px;
}

.management .filters .dropdown > div > div,
.subscriptions .filters .dropdown > div > div {
  color: #9b9b9d !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.management .header {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  padding: 10px 0 5px;
  .h2.mont { padding: 0 5px 0 0 !important; }
  &.footer { padding: 25px 0 0; }
  > div:last-of-type { text-align: right; }
}

.management .header .icon {
  color: #000 !important;
  width: 30px !important;
}

.management .header > div:first-of-type {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.management .header .curation-count {
  color: #1a1b33;
  font-weight: 700;
  &.over { color: #c61b1b; }
}

.management .label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  button { fill: #9b9b9d !important; }
  + div { margin: 0 0 0 -30px; }
}

.management .label > div:first-child {
  font-weight: 700 !important;
  height: 42px !important;
  left: -15px;
  top: -8px !important;
}

.management .label > div:nth-child(2) {
  left: -35px;
  position: relative;
}

.management .review {
  background-color: #f8f8f8 !important;
  svg { fill: #e6e7e8 !important; }
  td { color: #e6e7e8 !important; }
}

.management .review > td:first-child::before {
  color: #000;
  content: 'Curated Alert Under Review';
  font-style: italic;
  font-weight: 700 !important;
  margin: 0 0 0 -3%;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.management .sort {
  top: -8px !important;
}

.management .sort + button {
  left: -10px !important;
  span { color: #000 !important; }
}

.management table tbody {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.management table tbody td div {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.management table thead {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.management thead .sorting {
  left: -25px !important;
  padding: 0 !important;
}

.management tbody tr:hover > td {
  background-color: #f8f8f8 !important;
}

.dialog {
  z-index: 300 !important;
}

.dialog ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.dialog ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.dialog > div > div {
  max-width: 75% !important;
  width: 75% !important;
  h3 { margin: 0 0 0 -10px !important; }
}

.dialog .content {
  overflow: scroll !important;
  padding: 0 !important;
}

.subscriptions {
  padding: 0 24px;
}

.subscriptions .selection > div:nth-child(1) {
  align-items: center;
  display: flex;
}

.subscriptions .subscription-checkbox > div > div {
  justify-content: center;
}

.subscriptions .header-column .dropdown {
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.subscriptions .header-column .dropdown ~ .icon {
  color: #5c6ae0 !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 9px;
}

.subscriptions .header-column .dropdown > div > div {
  color: #5c6ae0 !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.dialog .content .table {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.dialog .content .table > div {
  margin: 0 -10px 0 0;
  width: 15%;
}

.dialog .content pre {
  max-height: 200px;
  overflow: auto;
  padding-right: 10px;
  white-space: pre-wrap !important;
  width: 100%;
}

.dialog .save div {
  color: #5c6ae0 !important;
  font-family: 'Montserrat';
}

.dialog .cancel div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
}

.iconOrange {
  @extend .icon;
  color: #5c6ae0 !important;
}
