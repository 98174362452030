@keyframes ellipsis {
  to {
    width: 20px;
  }
}

.pager {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.pager .active span {
  color: #5c6ae0 !important;
}

.pager .controls {
  display: flex;
}

.pager .controls.right {
  margin-left: auto; // align-right
}

.pager .icon.active {
  color: #5c6ae0 !important;
}

.pager .label {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.pager .label button {
  fill: #9b9b9d !important;
}

.pager .label .limit {
  font-size: 1.6rem !important;
  font-weight: 700;
  left: -10px !important;
  margin: 0 -20px 0 0 !important;
  top: -5px !important;
}

.pager .label .limit button {
  top: 10px !important;
}

.pager .label .limit button ~ div {
  border: 0 !important;
}

.pager .label + div {
  margin: 0;
}

.pager .loading {
  margin-top: 5px;
}

.pager .page {
  font-size: 1.6rem !important;
  margin: 0 15px 0 0 !important;
}

.pager .page + div {
  font-size: 1.6rem !important;
}

.pager .page input {
  font-weight: bold !important;
}

.pager .text {
  color: #9b9b9d;
  width: 115px;
}

.pager .text::after {
  animation: ellipsis steps(4, end) 0.9s infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0;
}

.pager .errText {
  color: #cb0025;
  width: 115px;
}

.pager .selected {
  padding: 0 0 0 5px;
}

.pager .translate {
  align-items: center;
  display: flex;
}

.pager form {
  padding: 0 0 0 15px;

  b {
    padding: 0 0 0 5px;
  }
}

.pager .bulk {
  margin-left: auto;

  button {
    width: 100% !important;
  }
}

.pager :global {
  .MuiTablePagination-root {
    overflow: initial;
  }

  .MuiOutlinedInput-input {
    min-height: auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: hidden;
  }
}

.menu {
  padding: 0 !important;
}

.item {
  line-height: 36px !important;
  min-height: 36px !important;
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.item .icon {
  height: 16px !important;
  width: 16px !important;
}

