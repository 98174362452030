.wiki-edit {
  margin: 0 auto;
  width: 950px;
}

.wiki-edit .footer {
  display: flex;
  justify-content: flex-end;
}

.wiki-edit .footer .actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wiki-edit .footer .actions > button {
  margin: 0 0 0 10px;
}

.wiki-edit .header {
  align-items: center;
  padding: 24px 0;
}

.wiki-edit .progress {
  position: absolute !important;
  right: 15px;
}

.wiki-edit .field {
  text-align: right;
  > div { padding-right: 24px !important; }
}

.wiki-edit .field .error {
  color: rgb(244, 67, 54);
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 0 0 0 1.5rem;
  text-align: left;
}

.wiki-edit .field .hint {
  padding: 0 0 1rem 1.5rem;
}

.wiki-edit .field .rte {
  border: 1px solid #9b9b9d !important;
  border-radius: 10px;
  max-width: 750px !important;
}

.wiki-edit .label {
  color: #79797b;
  font-size: 2em;
}

.wiki-edit input {
  background-color: #fff;
}

.wiki-edit .publish button span {
  padding: 0 24px !important;
}

.wiki-edit .row {
  padding-bottom: 24px;
}
