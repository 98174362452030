.integration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  padding: 15px;
}

.integration .actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .hidden { display: none !important; }
  .doc span { color: #5c6ae0 !important; }
}

.integration .actions div:first-child {
  max-width: 85%;
}

.integration .actions div > button {
  padding: 0 1.2rem 0 0;
}

.integration button[disabled] {
  min-width: 0 !important;
}

.integration button[disabled] span {
  padding: 0 5px !important;
}

.integration .content {
  align-items: center;
  display: flex;
}

.integration .image {
  width: 200px;
}

.integration .image img {
  max-height: 50px;
  min-height: 35px;
}

.integration .tooltip {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
}
