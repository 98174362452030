@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@mixin step-fade-in($count) {
  @for $i from 1 through $count {
    &:nth-of-type(#{$i}) {
      animation: fade-in .25s linear;
      animation-delay: (#{$i * .1s});
      animation-fill-mode: forwards;
      display: block;
      opacity: 0;
    }
  }
}

.reportscomp {
  width: 100%;
}

.reportscomp .empty {
  padding: 150px 0 0;
}

.reportscomp .load button {
  background-color: #5c6ae0 !important;
  font-family: 'Montserrat' !important;
  span { color: #fff !important; }
}

.reportscomp .load circle {
  opacity: 0 !important;
  stroke: #fff !important;
}

.reportscomp .load.loading circle {
  opacity: 1 !important;
}

.reportscomp .progress {
  margin: 15px 0 0;
}

.reportscomp .list a {
  @include step-fade-in(10);
}
