.knowledgebase {
  padding: 0;
}

.knowledgebase .anchor.h1 {
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.knowledgebase .anchor.h2 {
  margin: 0 0 0 25px !important;
  text-transform: capitalize !important;
}

.knowledgebase .anchor.h5 {
  margin: 0 0 0 25px !important;
  text-transform: capitalize !important;
}

.knowledgebase .content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1300px;
}

.knowledgebase .content .card {
  margin: 10px;
}

.knowledgebase .footer {
  margin: 10px 0 0;
  z-index: 1;
}

.knowledgebase .footer > div {
  border: 1px solid #999;
}

.knowledgebase .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.knowledgebase .header .title {
  align-items: center;
  display: flex;
}

.knowledgebase .header .controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.knowledgebase .empty {
  padding: 150px 0 0;
}
