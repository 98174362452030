.alerting .loading {
  padding-left: 5px;
}

.alerting .alerting-filters {
  padding: 1rem 0.5rem 0;
  width: 100%;
}

.alerting .alerting-filters.sticky {
  background-color: #fcfcfc;
  position: sticky;
  top: 135px;
  width: 105%;
  z-index: 99;
}
