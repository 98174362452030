@import './base.module';

.updates .body {
  padding: 0 50px;
  word-break: normal;
}

.updates .body a {
  color: #5c6ae0;
}

.updates .body .h1 {
  padding: 15px 0;
}

.updates .body .h2 {
  padding: 10px 0;
}

.updates .body .h4.a.disable {
  cursor: default !important;
  &:hover { opacity: 1 !important; };
}
