.base {
  font-size: 1.6rem;
  padding: 0 15px;
}

.base a {
  text-decoration: none !important;
}

.base .button a > div {
  box-shadow: none !important;
  padding: 15px 10px;
  div { font-family: 'Montserrat' !important; }
}

.base .button > a button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  span { color: #fff !important; }
}

.base .button > a button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.base .chips {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 50px;
}

.base .chip {
  background-color: #5c6ae0 !important;
  cursor: pointer !important;
  margin: 0 10px 10px 0 !important;
  text-transform: capitalize !important;
  button { color: #9b9b9d !important; }
  span { color: #fff !important; }
  svg { fill: #fff !important; }
  > span { padding-left: 5px !important; }
  :hover { opacity: .8; }
}

.base .chip .avatar {
  background-color: #5c6ae0 !important;
  color: #fff !important;
}

.base .description {
  padding: 15px 0 0;
}

.base .graph {
  padding: 10px 0;
}

.base .form .icon {
  cursor: pointer;
  position: relative !important;
  z-index: 200;
}

.base .form .icon span {
  color: #9b9b9d !important;
}

.base .form .icon.search span {
  color: #5c6ae0 !important;
}

.base .inline {
  padding: 15px 0;
}

.base .map {
  margin: 0 -15px 35px;
}

.base .media {
  align-items: center;
  direction: ltr;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  margin: 10px 0;
}

.base .media .title {
  align-items: center;
  background-color: #79797b;
  border: 2px solid #79797b;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  text-transform: uppercase;
}

.base .media .title div:first-child {
  opacity: .6;
  padding: 0 5px 0 0;
  text-transform: capitalize;
}

.base .media .title div:nth-child(2) {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base .media .content {
  align-items: center;
  border: 2px solid #79797b;
  color: #000;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
}

.base .media .safe.message {
  flex: 1 1 100%;
}

.base .search {
  color: #000;
  padding: 15px 0 50px;
  word-break: break-word;
}

.base .search .inline {
  display: flex;
}

.base .search .inline .h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 45%;
}

.base .search .inline .h3 {
  padding: 0 0 0 5px;
}

.base .search .a .normal {
  color: #000;
}

.base .search .h2 {
  padding: 35px 0 15px;
}

.base .search .button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 35px !important;
  line-height: 35px !important;
  margin: 15px 0 0 !important;
}

.base .search .button span {
  color: #fff !important;
}

.base .search .chips {
  padding: 10px 0;
}

.base .search .chips > div {
  padding: 5px 0 0;
}

.base .search .form {
  align-items: center;
  border-bottom: 1px solid #e6e7e8;
  display: flex;
  justify-content: space-between;
}

.base .search .form .hr {
  margin: 35px 0 0;
}

.base .search .form .icon {
  cursor: pointer;
  position: relative !important;
  z-index: 200;
}

.base .search .form .icon.close {
  margin: 0 -15px 0 0 !important;
}

.base .search .form .icon span {
  color: #9b9b9d !important;
}

.base .search .form .icon.search span {
  color: #5c6ae0 !important;
}

.base .search .filters {
  margin: 15px 0 5px;
}

.base .search .results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.base .search .results .empty {
  padding: 50% 25px 0;
  text-align: center;
  text-transform: none;
}

.base .search .result {
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  justify-content: space-between;
  padding: 10px 0;
}

.base .search .result .index {
  width: 30px;
}

.base .search .result .post {
  border-radius: 5px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24);
  overflow: hidden;
  padding: 5px 10px;
  position: relative;
  width: 100%;
  word-break: break-word;
  &.selected { border-left: 10px solid #5c6ae0; }
  &:hover { box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23); }
}

.base .search .result .post .a {
  color: #5c6ae0 !important;
  margin: -5px 0 5px !important;
  text-decoration: none !important;
}

.base .search .result .post .launch {
  height: 30px !important;
  padding: 0 !important;
  position: absolute !important;
  right: 0;
  top: 0;
  width: 34px !important;
  span { font-size: 2rem !important; }
}

.base .search .truncate {
  overflow: hidden;
}

.base .search .truncate > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.base .table {
  align-items: flex-start;
  color: #000;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  word-break: break-all;
}

.base .table [name='cards.date.tooltip'] {
  margin: -10px 0 0 0 !important;
}

.base .table >div:first-child {
  min-width: 135px;
  text-transform: uppercase;
}

.base .table >div:nth-child(2) {
  display: flex;
  flex-flow: column wrap;
  word-break: break-word;
  >div { word-break: break-word; }
}

.base .table .column {
  flex-flow: column wrap;
}

.base .table svg {
  overflow: visible;
}

.base .tabs {
  padding: 15px 0 0;
}

.base .tabs .active > div > div {
  color: #5c6ae0 !important;
}

.base .tabs button > div > div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
  font-weight: 600;
}

.base .tabs > div:first-child {
  background-color: #fff !important;
  color: #5c6ae0 !important;
}

.base .tabs > div:nth-child(2) > div {
  background-color: #5c6ae0 !important;
  height: 3px !important;
}

.base .quotes .quote {
  color: #5c6ae0;
  cursor: pointer;
  text-decoration: underline;
}
