.bookmark {
  display: inline-block !important;
  height: 20px;
  position: relative !important;
}

.bookmark .icon {
  color: #9b9b9d !important;
}

.bookmark .hover:hover > div > span {
  color: #000 !important;
}

.actions button div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
}

.actions .active span {
  color: #5c6ae0 !important;
}
