.base {
  border-radius: 5px !important;
  overflow: visible;
  padding: 0 0 35px;
}

.base a {
  color: #000 !important;
  text-decoration: none !important;
}

.base .active {
  background-color: #f7f5f5 !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  a { color: #000 !important; }
}

.base .button.edit {
  position: absolute;
  right: 35px;
}

.base .button button {
  background-color: #5c6ae0 !important;
  span { color: #fff !important; }
}

.base .card {
  border-radius: 5px !important;
  margin: 0;
  &.loaded >div:nth-child(2) { box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important; }
  >div { overflow: visible !important; }
}

.base .dialog {
  z-index: auto !important;
}

.base .empty {
  margin: 250px auto 0 !important;
}

.base .empty .link {
  color: #5c6ae0 !important;
  cursor: pointer;
}

.base.empty .link:hover {
  opacity: .6;
}

.base .filters > div {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 15px;
  > div { padding: 0 15px 0 0; }
}

.base .filters .dropdown {
  background-color: #f7f5f5 !important;
  border: 2px solid #e6e7e8 !important;
  border-radius: 5px !important;
  color: #18191c !important;
  line-height: 28px !important;
}

.base .filters .dropdown span {
  color: #18191c !important;
  text-transform: none !important;
}

.base .filters .dropdown .icon {
  color: #9b9b9d !important;
  margin: 0 5px;
  position: relative;
}

.base .filters .form {
  align-items: center;
  display: flex;
}

.base .filters .input {
  left: -20px !important;
  text-indent: 25px;
}

.base .filters .icon {
  color: #5c6ae0 !important;
  &.close { color: #9b9b9d !important; }
}

.base .filters .icon.close {
  margin: 0 0 0 -50px;
  opacity: 0;
  padding: 0 0 0 10px;
}

.base .filters .icon.close.show {
  opacity: 1;
}

.base .header {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px;
}

.base .header .icon {
  color: #000 !important;
}

.base .header > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.base .icon.save {
  padding: 0 3px !important;
  top: 5px;
}

.base .label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  button { fill: #9b9b9d !important; }
  + div { margin: 0 0 0 -30px; }
}

.base .label > div:first-child {
  font-weight: 700 !important;
  height: 42px !important;
  left: -15px;
  top: -8px !important;
}

.base .label > div:nth-child(2) {
  left: -35px;
  position: relative;
}

.base .review {
  background-color: #f8f8f8 !important;
  svg { fill: #e6e7e8 !important; }
  td { color: #e6e7e8 !important; }
}

.base .review > td:first-child::before {
  color: #000;
  content: 'Curated Alert Under Review';
  font-style: italic;
  font-weight: 700 !important;
  margin: 0 0 0 -3%;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.base .sort {
  top: -8px !important;
}

.base .sort + button {
  left: -10px !important;
  span { color: #000 !important; }
}

.dialog {
  z-index: 300 !important;
}

.dialog > div > div {
  max-width: 75% !important;
  width: 75% !important;
  h3 { margin: 0 0 0 -10px !important; }
}

.dialog .content {
  overflow-x: hidden;
  padding: 0 !important;
}
