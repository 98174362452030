.post {
  align-items: flex-start;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  margin: 35px 0;
}

.post .a.disabled {
  pointer-events: none;
}

.post .translation {
  align-items: center;
  display: flex;
}

.post .translation > div:nth-child(2) {
  margin: -5px 0 0 10px;
}

.post.parent .card {
  margin-right: 100px !important;
  width: 850px !important;
}

.post [mode='indeterminate'] {
  margin: 0 10px 0 0;
}

.post .active {
  border-left: 10px solid #5c6ae0;
}

.post .body {
  word-break: break-word;
  blockquote { margin: 0 !important; }
  blockquote b { font-weight: 400; }
  p { margin: 0 !important; }
  .placeholder { color: #9b9b9d; }
}

.post .body.hide {
  opacity: 0;
}

.post .body a {
  color: #5c6ae0 !important;
  text-decoration: none !important;
}

.post .body blockquote blockquote,
.post .body > div > blockquote > div,
.post .body > div > blockquote > b > div,
.post .body > div > div > blockquote > div,
.post .body > div > div > blockquote > b > div {
  border-left: 3px solid #e6e7e8;
  margin: 5px 0 15px;
  padding: 0 0 0 10px;
}

.post .body :global .quote {
  border-left: 3px solid #e6e7e8;
  color: #9b9b9d;
  margin: 5px 0 15px;
  padding: 0 0 0 10px;
}

.post .body .containsJSON {
  white-space: pre-wrap;
}

.post .card {
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  padding: 15px;
  width: 750px !important;
}

.post .card.wide {
  transform: translate3d(0, 0, 0);
  transition: width .5s;
  width: 1024px !important;
}

.post .container {
  display: flex;
  justify-content: space-between;
}

.post .container > div {
  width: 100%;
}

.post .container > div:nth-child(2) {
  padding: 0 0 0 35px;
}

.post .container .item {
  animation: fadeIn 2.5s;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  margin: 15px 0 0;
  max-width: 420px;
  padding: 15px;
  word-wrap: break-word;
}

.post .ogtext {
  background-color: #fff;
  border: 1px solid #5c6ae0;
  border-radius: 5px;
  margin: 15px 0 0;
  max-width: 420px;
  padding: 15px;
  word-wrap: break-word;
}

.post .translated {
  background-color: #fff;
  border: 1px solid #5c6ae0 !important;
}

.post .container .item > div > blockquote {
  margin: 0 !important;
}

.post .header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.post .site p {
  margin: -10px 0 0 0;
  padding: 0;
}

.post .sitetitle {
  color: #5c6ae0;
}

.post .header > div > div {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}

.post .header .h4 {
  padding: 0 5px 0 0;
}

.post .header .icon {
  padding: 0 5px 0 0;
}

.post .icon {
  color: #9b9b9d !important;
}

.post .country {
  color: #79797b;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 5px;
}

.post .media {
  align-items: center;
  direction: ltr;
  display: flex;
  font-size: 1.6rem;
  margin: 10px 0;
}

.post .media .title {
  align-items: center;
  background-color: #79797b;
  border: 2px solid #79797b;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  text-transform: uppercase;
}

.post .media .title div:first-child {
  opacity: .6;
  padding: 0 5px 0 0;
  text-transform: capitalize;
}

.post .media .title div:nth-child(2) {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post .media .content {
  align-items: center;
  border: 2px solid #79797b;
  color: #000;
  display: flex;
  justify-content: flex-start;
  min-width: 10%;
  padding: 5px;
}

.post .media .content > div:first-child {
  max-width: 250px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.post .original {
  animation: fadeIn  1s;
  color: #79797b;
  font-family: serif;
  font-size: 13px;
}

.post pre {
  background-color: #f8f8f8;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  height: auto !important;
  padding: 15px;
  white-space: normal;
  width: auto !important;
  word-wrap: break-word;
}

.post .tools {
  align-items: flex-start;
  display: flex;
  margin: 0 -15px 0 0;
  opacity: 1;
}

.post .quotes {
  padding: 0 0 10px;
}

.post .quotes .quote {
  color: #5c6ae0;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.post .quotes .quote.disabled {
  color: inherit;
  cursor: auto;
  text-decoration: none;

  span { cursor: auto; }
}

.post .index {
  align-items: center;
  border: 2px solid #9b9b9d;
  border-radius: 3rem;
  color: #9b9b9d;
  display: flex;
  justify-content: center;
  min-height: 10px;
  min-width: 20px;
  padding: 5px;
  position: relative;
  right: 10px;
  top: 13px;
}

.post .index.active {
  background-color: #5c6ae0;
  border-color: #5c6ae0;
  color: #fff;
}

.post .switch {
  font-family: 'Montserrat';
  font-size: 11px !important;
}

.post .unavailable span {
  display: flex;
  justify-content: center;
}

.post .nested {
  display: flex;
  flex-direction: column;
}

.post .nested .load {
  color: #9b9b9d;
  text-align: right;

  .link { display: inline-block; }
  .icon { top: 6px; }
  .a .icon { color: #5c6ae0 !important; }
}

// Compact Posts
.post.compact {
  flex-direction: row !important;
  margin: 0 !important;

  .time { order: 0; }
  :global .react-contextmenu-wrapper { order: 1; }
  .author { order: 1; }
  .body { order: 2; }
  .tools { order: 3; }
}

.post.compact .card {
  background-color: transparent !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  display: flex !important;
  min-height: 30px !important;
  padding: 0 !important;
  position: relative !important;
  width: 100% !important;

  .body { padding: 5px 10px !important; }
}

.post.compact .card.active {
  border-left: 5px solid #5c6ae0 !important;

  .header { padding-left: 5px; }
}

.post.compact .header {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  display: block !important;
  padding-left: 10px;
  width: auto !important;
}

.post.compact .author {
  padding: 0 10px;
  width: 150px;
  word-wrap: break-word;
}

.post.compact .time {
  width: 200px;
}

.post.compact .tools {
  border-right: 1px solid #fff;
  opacity: 1 !important;
  position: absolute !important;
  right: 65px;
  top: 0;
  width: 130px;
}

.post.compact .tools button {
  height: 24px !important;
  padding: 6px !important;
  width: 24px !important;
}

.post.compact .container {
  border-right: 1px solid #fff;
  justify-content: flex-start !important;
  margin-right: 180px !important;
  width: 100% !important;
}

.post.compact .container .ogtext,
.post.compact .container .item {
  margin: 0 !important;
  max-width: none;
}
