@import '../base.module';

.pastes .header button {
  margin: 15px 0 0;
}

.pastes .card > div {
  word-break: break-all;
}

.pastes .removed {
  padding: 15px 0 0 5px;
}
