.placeholder {
  padding: 50px 0 50px 35px;
  transform: scale(.95);
}

.placeholder .card {
  background-color: #fff;
  padding: 25px 25px 0;
}

.placeholder .card svg {
  margin: 0 0 0 -100px;
}
