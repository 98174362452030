@import './base.module';

.sitesfilter {
  min-width: 650px;

  &.advanced { min-width: auto; }
}

.sitesfilter .advanced {
  display: none;
}

.sitesfilter .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.sitesfilter .dropdown,
.sitesfilter .dropdown * {
  color: #9b9b9d !important;
  &:not(.icon) { text-transform: capitalize !important; }
}

.sitesfilter .filters {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 0 15px;
}

.sitesfilter .filters > span {
  padding: 0 10px;
}

.sitesfilter .form {
  border: 1px solid #e6e7e8;
  margin: 15px 0 0 !important;
}

.sitesfilter .list {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  border-top: 0;
  height: 235px;
  overflow-y: scroll;
  >div>div>div>div { padding: 0 56px 0 16px !important; }
  >div>div:hover :global .material-icons { opacity: 1 !important; }
}

.sitesfilter .selected .h2 {
  padding: 0 0 15px;
}

.sitesfilter .selected .list {
  border-top: 1px solid #e6e7e8;
  height: 285px;
}

.sitesfilter .selected > div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.sitesfilter .selected > div:nth-child(2) {
  top: -5px;
}

.sitesfilter .unselected > div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.menu {
  padding: 0 !important;
}
