.slide {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: absolute;
  right: 2rem;
  width: 100%;
}

.left {
  align-items: left;
}

.slide .content {
  display: none;
  justify-content: flex-start;
  padding: 0 0 0 50px;
  position: absolute;
  width: 100%;
}

.slide.video .content {
  display: flex;
}

.slide.video .content video {
  max-height: 85vh;
  /* stylelint-disable unit-whitelist */
  max-width: 50vw;
}

.slide .footer {
  display: flex;
  flex-direction: column;
  padding: 3.5rem 0 0;
  width: 30rem;
}

.slide .header {
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.slide table td {
  overflow: hidden;
  vertical-align: top;
  word-break: break-word;
}

.slide table td:first-child {
  font-weight: bold;
  max-width: 6rem;
  min-width: 6rem;
  width: 6rem;
}

.slide table td:nth-child(2) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 6;
}

@media (min-width: 1024px) {
  .slide .content {
    justify-content: center;
    padding: 0;
    width: 100%;
  }
}
