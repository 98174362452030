.media-grid-image-fluid {
  cursor: pointer;
  margin: 2px;
  position: relative;
}

.media-grid-image-fluid img {
  backface-visibility: hidden;
  display: block;
  height: auto;
  max-width: 350px;
  min-width: 200px;
  opacity: 1;
  padding: 2px;
  transition: .5s ease;
  width: 100%;
}

.media-grid-image-fluid.small,
.media-grid-image-fluid.small img {
  max-width: 250px !important;
  min-width: 150px !important;
}

.media-grid-image-fluid .overlay {
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: .5s ease;
  width: 100%;
  > div { height: 100%; }
}

.media-grid-image-fluid .overlay .lightbox {
  left: 5px;
  position: absolute;
  top: 5px;
  z-index: 100;
}

.media-grid-image-fluid:hover img {
  opacity: 0.3;
}

.media-grid-image-fluid:hover .overlay {
  opacity: 1;
}
