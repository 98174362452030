.brand {
  margin: 0 0 100px;
  width: 100%;
}

.brand .assetList {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1rem 0;
}

.brand .assetListItem {
  &::after {
    content: ', ';
  }

  &:last-child::after {
    content: '';
  }
}

.brand .disclaimer {
  color: rgba(0, 0, 0, 0.54);
  margin: 5rem 2rem 0;
}

.brand .dropzone {
  margin: 0 0 15px;
  padding: 0 25px;
}

.brand .filters {
  margin: 0 0 -50px;
}

.brand .loa {
  border: 1px #808080 solid;
  border-radius: 4px;
  margin: 0 2rem;
  padding: 1.6rem;
}

.brand .textMargins {
  margin: 0 2rem;
}

.brand .fieldMargins {
  margin: 2rem 2rem 0;
}

.brand .listItem {
  color: #5c6ae0;
}
 
.brand .footer {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e6e7e8;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  position: absolute !important;
  right: 0;
  width: 100%;
  z-index: 100;
}

.brand .footer .status {
  color: #f01;
}

.brand :global .dzu-dropzone {
  border: 1px dashed #9b9b9d;
  min-height: 35px !important;
  overflow: auto !important;
}

.brand :global .dzu-previewContainer {
  background-color: #e6e7e8;
  padding: 0 3% !important;
}

.brand :global .dzu-previewFileName {
  color: #5c6ae0;
}

.brand :global .dzu-inputLabelWithFiles {
  background-color: #fff;
  margin: 0;
  width: 100%;
}

.brand :global .MuiFormControlLabel-root {
  color: rgba(0, 0, 0, .54);
  margin: 1rem 0;
}
