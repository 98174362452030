.twitter {
  margin-left: 25%;
  width: 550px;
}

.twitter .tools {
  margin: 15px 0 0 400px;
  position: absolute;
}

.twitter .tools .tool {
  margin: 0 0 0 10px;
}

