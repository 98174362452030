@media (min-width: 1px) {
  .padSmall {
    margin: auto;
    max-width: 99%;
    padding: 0 1.75rem;
  }
}
@media (min-width: 1315px) {
  .padSmall {
    margin: auto;
    max-width: 96%;
    padding: 0 3.75rem;
  }
}
@media (min-width: 1515px) {
  .padSmall {
    margin: auto;
    max-width: 96.5%;
    padding: 0 3.75rem;
  }
}
@media (min-width: 1900px) {
  .padSmall {
    margin: auto;
    max-width: 97.5%;
    padding: 0 3.75rem;
  }
}
@media (min-width: 2400px) {
  .padSmall {
    margin: auto;
    max-width: 98%;
    padding: 0 3.75rem;
  }
}
@media (min-width: 3800px) {
  .padSmall {
    margin: auto;
    max-width: 98.5%;
    padding: 0 4rem;
  }
};

.card {
  border-radius: 5px !important;
  margin: 0;
  &.loaded >div:nth-child(2) { box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important; }
  >div { overflow: visible !important; }
}
