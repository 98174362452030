.integrations {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.integrations .integration {
  margin: 15px;
  width: 45%;
}
