
/* stylelint-disable */
/* https: //projects.lukehaas.me/css-loaders/ */
.loader {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.loader.hidden {
  opacity: 0;
}

.loader.bar,
.loader.bar:before,
.loader.bar:after {
  background: var(--bg-icon);
  animation: bar 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader.bar {
  color: var(--bg-icon);
  text-indent: -9999em;
  margin: 10px 0 0 10px;
  position: relative;
  font-size: 5px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader.bar:before,
.loader.bar:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader.bar:before {
  left: -1.5em;
  animation-delay: -0.32s;
}

.loader.bar:after {
  left: 1.5em;
}

.loader.xxs.bar {
  font-size: 3px;
}

.loader.sm.bar {
  font-size: 7px;
}

.loader.md.bar {
  font-size: 9px;
}

.loader.lg.bar {
  font-size: 11px;
}

.loader.bubble {
  color: var(--bg-icon);
  font-size: 5px;
  margin: 0 0 0 20px;
  position: relative;
  text-indent: -9999em;
  top: -10px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader.bubble,
.loader.bubble:before,
.loader.bubble:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bubble 1.8s infinite ease-in-out;
}

.loader.bubble:before,
.loader.bubble:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader.bubble:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader.bubble:after {
  left: 3.5em;
}

.loader.xxs.bubble {
  font-size: 3px;
  margin: 0 0 0 10px;
  top: -5px;
}

.loader.sm.bubble {
  font-size: 8px;
  margin: 0 0 0 30px;
  top: -15px;
}

.loader.md.bubble {
  font-size: 11px;
  margin: 0 0 0 45px;
  top: -20px;
}

.loader.lg.bubble {
  font-size: 14px;
  margin: 0 0 0 55px;
  top: -30px;
}

.loader.spinner {
  font-size: 0.5rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25em solid rgba(142, 150, 177, 0.2);
  border-right: 0.25em solid rgba(142, 150, 177, 0.2);
  border-bottom: 0.25em solid rgba(142, 150, 177, 0.2);
  border-left: 0.25em solid var(--bg-icon);
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
}

.loader.spinner,
.loader.spinner:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}

.loader.xxs.spinner {
  font-size: 0.35rem;
}

.loader.sm.spinner {
  font-size: var(--font-sm);
}

.loader.md.spinner {
  font-size: var(--font-md);
}

.loader.lg.spinner {
  font-size: var(--font-lg);
}

@keyframes bar {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes bubble {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
