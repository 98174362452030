@import './base.module';

.listfilter {
  min-width: 350px;
}

.listfilter .advanced {
  display: none;
}

.listfilter .error {
  margin-left: 15px;
}

.listfilter .form {
  border: 1px solid #e6e7e8;
  margin: 15px 0 3px !important;
}

.listfilter .list {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  border-top: 0;
  height: 235px;
  overflow-y: scroll;
}

.listfilter .selected .h2 {
  padding: 0 0 15px;
}

.listfilter .selected .list {
  border-top: 1px solid #e6e7e8;
  height: 285px;
}

.listfilter .selected > div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
}

.listfilter .unselected > div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
}
