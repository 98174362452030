.tagger {
  padding: 15px;
}

.tagger .tagsheader {
  color: #79797b !important;
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin-top: 30px !important;
}

.tagger .tagssubheader {
  color: #79797b !important;
  font-size: 1.4rem;
  font-weight: 200 !important;
  margin-top: 10px !important;
}

.tagger .buttoncontainer {
  display: flex;
  justify-content: space-around;
}

.tagger .tags .button {
  background-color: #5c6ae0 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.tagger .tags .button.cancel {
  background-color: #9b9b9d !important;
  margin-right: 10px !important;
}

.tagger .actions {
  margin-top: 10px;
}

.tagger .tagscontainer {
  margin-bottom: 50px;
}

.tagger .row .label {
  color: #1a1b33;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 10px 0;
}

.tagger .row .label.changed::after {
  color: #5c6ae0;
  content: '*';
}

.tagger .row .button {
  border: 1px solid #303137 !important;
  border-radius: 5px !important;
  color: #303137 !important;
  height: auto !important;
  line-height: inherit !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.tagger .row .button span {
  text-transform: none !important;
}

.tagger .row .button:hover,
.tagger .row .button.selected {
  background-color: #5c6ae0 !important;
  border: 1px solid #5c6ae0 !important;
  color: #fff !important;
}

.tagger .row.no-channel {
  font-weight: 700;
  padding: 20px 0;
}
