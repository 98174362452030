@import '../base.module';

.settings .a + .a {
  padding: 0 15px;
}

.settings .api {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 -25px;
  padding: 15px 0;
}

.settings .api > div {
  align-items: center;
  display: flex;
}

.settings .api .icon {
  margin: 0 -15px 0 0;
}

.settings .apitoken .hover:hover {
  color: #000 !important;
}

.settings .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  span { color: #fff !important; }
  div { height: auto !important; }
}

.settings .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.settings .border {
  border: 1px solid #e6e7e8;
  margin: 15px 0 0;
}

.settings .border > div {
  padding: 10px !important;
}

.settings legend {
  font-size: 1.6rem;
  font-weight: 600;
}

.settings .disclaimer {
  padding: 15px 0;
  text-transform: uppercase;
}

.settings .header {
  align-items: center;
  display: flex;
}

.settings .boxes {
  width: 100%;
}

.settings .input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  >div:first-child { min-width: 75%; }
  .button { box-shadow: none !important; }
}

.settings .tfa {
  path { fill: #5c6ae0 !important; }
}

.settings .tfa .confirm span {
  color: #5c6ae0 !important;
}

.settings .tfa .content {
  padding: 0 15px;
  word-break: normal;
}

.settings .tfa .input {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 35px 0 0;
  .button { box-shadow: none !important; }
}

.settings .tfa .input.done {
  justify-content: flex-start;
  margin: -35px 0 0 -5px;
}

.settings .tfa .qr {
  align-items: center;
  display: flex;
  flex-direction: column;
  >div:first-child { padding: 15px 0; }
}

.settings .tfa {
  path { fill: #5c6ae0 !important; }
}

.settings .tfa .confirm span {
  color: #5c6ae0 !important;
}

.settings .tfa .content {
  padding: 0 15px;
  word-break: normal;
}

.settings .tfa .input {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 35px 0 0;
  .button { box-shadow: none !important; }
}

.settings .tfa .input.done {
  justify-content: flex-start;
  margin: -35px 0 0 -5px;
}

.settings .tfa .qr {
  align-items: center;
  display: flex;
  flex-direction: column;
  >div:first-child { padding: 15px 0; }
}

.settings .token {
  background-color: #e6e7e8;
  margin: 15px 0;
  padding: 15px;
  word-break: break-all;
}
