@import './base.module';

.marketplaces {
  padding: 0 2rem !important;
  position: relative;
}

.marketplaces.pull {
  right: 20%;
}

.marketplaces .translation {
  align-items: center;
  display: flex;
}

.marketplaces .translation > div:nth-child(2) {
  margin: -5px 0 0 10px;
}

.marketplaces.wide {
  max-width: none !important;
  transform: translate3d(0, 0, 0);
  transition: margin, width ease .5s;
  width: 1024px;

  &.pull { width: 850px; }
}

.marketplaces [mode='indeterminate'] {
  margin: 0 10px 0 0;
}

.translate.on span {
  color: #5c6ae0 !important;
}

.marketplaces .container {
  display: flex;
  justify-content: space-between;
}

.marketplaces .container > div {
  width: 100%;
}

.marketplaces .a {
  max-width: 500px;
}

.marketplaces .container > div:nth-child(2) {
  padding: 0 0 0 35px;
}

.marketplaces .card {
  overflow-wrap: break-word;
}

.marketplaces .card .header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.marketplaces .card .header .h2 {
  padding: 0 0 15px;
}

.marketplaces .card .hero {
  padding: 0 0 10px !important;
  .h2 { padding: 0 0 10px; }
}

.marketplaces .item {
  animation: fade-in 3s;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  margin: 15px 0 0;
  max-width: 420px;
  padding: 15px;
  word-wrap: break-word;
}

.marketplaces .ogtext {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  margin: 15px 0 0;
  max-width: 420px;
  padding: 15px;
  word-wrap: break-word;
}

.marketplaces .original {
  animation: fadeIn  2s;
  color: #79797b;
  font-family: serif;
  font-size: 13px;
}

.marketplaces .original + .item {
  margin: 5px 0 0;
}

.marketplaces .switch {
  font-family: 'Montserrat';
  font-size: 11px !important;
}

.marketplaces .title {
  word-break: break-word;
}
