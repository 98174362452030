.sidebar {
  background: #fff;
  border-left: 1px solid #e6e7e8;
  font-size: 1.6rem;
  height: calc(100vh);
  overflow-x: hidden;
  padding: 0;
  position: fixed;
  right: -350px;
  transition: right .5s;
  width: 350px;
  z-index: -100;
}

.sidebar .button {
  margin: 15px 0;
}

.sidebar .header {
  align-items: center;
  display: flex;
  padding: 15px 15px 0;
  > div { width: 100%; }
}

.sidebar .header .h2 {
  overflow-wrap: break-word;
  width: 75%;
}

.sidebar .header .h2 div {
  overflow-wrap: break-word;
}

.sidebar .header .h3 {
  overflow-wrap: break-word;
  width: 80%;
}

.sidebar .header .icon {
  color: #000 !important;
  margin: 0 5px;
}

.sidebar .header .icon.close {
  position: absolute !important;
  right: 0;
  top: 15px;
}

.sidebar.pinned {
  overflow-y: scroll;
  right: 0;
  top: 0;
}

.sidebar.pinned > div {
  left: 0;
  position: absolute;
  right: 0;
  top: 75px;
}

.sidebar .progress {
  margin: 15px;
}

.sidebar pre {
  background-color: #e6e7e8;
  border-radius: 3px;
  padding: 5px 10px;
}

.sidebar :global .highlight {
  word-break: break-word;
}

@media print {
  .sidebar { display: none; }
}
