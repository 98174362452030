@import './base.module';

.iocs {
  width: 100%;
}

.iocs .body {
  overflow: hidden;
  text-overflow: ellipsis;
}

.iocs .description {
  padding: 0 0 35px;
}

.iocs .card {
  min-height: 50vh;
}

.iocs .card .h2 {
  padding: 15px 0;
}

.iocs .configs {
  border: 1px solid #e6e7e8;
  min-height: 50vh;
}

.iocs .configs pre {
  white-space: pre-wrap;
}

.iocs .filters {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 15px 0;
  padding: 5px;
}

.iocs .filters label {
  white-space: nowrap;
} 

.iocs .flex {
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
}

.iocs .graph {
  border: 1px solid #e6e7e8;
}

.iocs .icon.color {
  display: inline-block !important;
  margin: 0 5px 0 0;
  min-height: 15px !important;
  min-width: 15px !important;
}

.iocs .info {
  border: 1px solid #9b9b9d;
  padding: 5px 10px;
}

.iocs .info .icon {
  padding: 0 5px;
  top: 5px;
}

.iocs .ioctable {
  margin: 0;
  padding: 15px 0;
}

.iocs .table {
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.iocs .table >div:first-child {
  margin: 0;
  min-width: 0;
}

.iocs .tabs > div:first-of-type {
  background-color: transparent !important;
  button { color: #79797b !important; }
}

.iocs .tabs .content {
  padding: 10px 25px;
}

.iocs .tabs .content .row:not(:last-child) {
  border-bottom: 1px solid #e6e7e8;
  padding: 10px 0;
}

.iocs :global table td:last-child {
  overflow: visible !important;
  white-space: normal !important;
  word-break: break-all;
}
