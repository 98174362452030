.aggregations {
  margin: 0;
  padding: 0;
  width: 100%;
}

.aggregations .charts {
  align-items: center;
  display: flex;
  position: relative;
}

.aggregations .charts > div > div {
  padding: 0 !important;
}

.aggregations .edit {
  position: absolute !important;
  right: -15px;
  top: -10px;
}

.aggregations .edit .icon {
  color: #9b9b9d !important;
}

.menu {
  padding: 0 !important;
}

.item {
  line-height: 36px !important;
  min-height: 36px !important;
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.item .icon {
  height: 16px !important;
  width: 16px !important;
}
