.malware a {
  color: #5c6ae0;
}

.malware .aliases > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
}

.malware .aliases div {
  margin: 0 5px 0 0;
  pointer-events: none;
}

.malware .counts {
  align-items: flex-start;
  display: flex;
}

.malware .dates {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.malware .dropdown {
  border: 1px solid #1a1b33;
  border-radius: 5px;
  color: #fff;
  margin: 5px;
  position: relative;
}

.malware .dropdown .badge {
  background-color: #5c6ae0 !important;
  border-radius: 35px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
  color: #fff !important;
  height: 20px;
  overflow: hidden;
  padding: 1px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: capitalize !important;
  top: -10px;
}

.malware .dropdown .button {
  background-color: #1a1b33;
  padding: 5px 10px;
  text-align: center;
}

.malware .dropdown .content {
  background-color: #fff;
  border: 1px solid #9b9b9d;
  cursor: default;
  display: none;
  position: absolute;
}

.malware .dropdown .content > div {
  border-top: 1px solid #9b9b9d;
  color: #1a1b33;
  padding: 5px 10px;
  text-align: left;
}

.malware .dropdown .link {
  cursor: pointer;
}

.malware .dropdown .link:hover {
  opacity: .6;
}

.malware .dropdown:hover .content {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  z-index: 200;
}

.malware .h1 {
  font-weight: 400 !important;
}

.malware .header {
  align-items: center;
  display: flex;
}

.malware .header .icon {
  font-size: 1.8rem !important;
  margin: -5px 0 0 -7px !important;
}

.malware .description > p {
  margin-top: 0;
}

.malware .overview .title {
  font-family: 'open sans';
  font-size: 2rem;
  font-weight: 600;
}

.malware .section {
  background-color: #fff;
  border: 1px solid #9b9b9d;
  display: flex;
  margin: 5px 0 0;
  overflow: hidden;
  padding: 15px;
}

.malware .section.attacks {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  margin: 35px 0 0;
  overflow: visible;
}

.malware .section.iocs,
.malware .section.reports,
.malware .section.mentions {
  height: 165px;
}

.malware .section.tags,
.malware .section.indicators {
  height: 335px;
}

.malware .title {
  align-items: center;
  display: flex;
  padding: 15px 0 0;
}

.malware .title .h1 {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.malware .title .menu {
  height: 15px !important;
  margin: -30px 0 0 !important;
}
