.bars svg text tspan {
  fill: #c3c3c7 !important;
}

.bars .tooltip {
  background-color: #000;
  color: #fff;
  padding: 5px;
}

.bars .tooltip .tip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 350px;
}
