.line-chart {
  position: relative;
}

.line-chart .range {
  position: absolute;
  right: 0;
  top: 0;
}

.line-chart .range > button {
  margin: 0 10px !important;
}

.line-chart .range button:not([disabled]) {
  background-color: #5c6ae0 !important;
}

.line-chart .range button:not([disabled]) span {
  color: #fff !important;
}
