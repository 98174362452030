/* stylelint-disable string-quotes */
.containers {
  display: flex;
  flex-direction: column;
}

.containers .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;

  button {
    display: none !important;
  }

  button ~ div {
    display: none !important;
  }

  span:not(.icon) {
    text-transform: capitalize !important;
  }
}

.containers .dropdown ~ .icon {
  color: #9b9b9d !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 3px;
}

.containers .dropdown > div > div {
  color: #9b9b9d !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.containers .sections {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containers .section {
  display: flex;
}

.homeDashboard {
  display: flex;
  flex: 1;
  flex-flow: column;
  height: calc(100vh - 75px);
  padding-left: 20px;

  div[class*='dashboard'] {
    flex: 1;
    height: initial;
  }
}

.homeDashboard .middleRow {
  flex: 1;
  max-height: 575px;
  min-height: 292px;

  div {
    max-height: 100%;
  }
}

.homeDashboard .bottomRow {
  flex: 0 0 292px;
}

.homeDashboard .header {
  flex: 0 0 88px;
  padding: 25px 0 15px;
}

.homeDashboard .header .date {
  background-color: #fff;
  border: 1px solid #e6e7e8;
}

.homeDashboard .bigRowsContainer {
  display: flex;
  flex: 1;
  flex-flow: column;
  min-height: 0;
}

.homeDashboard .smallRow {
  div[class*='card'] {
    max-height: 200px;
  }
}

.homeDashboard .topSourceFlexGrow {
  display: flex;
  flex-flow: column;
  height: 100%;
  line-height: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.homeDashboard .sourceText {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1;
  margin: 1rem 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flexGrowContainer {
  display: flex;
  flex: 1;
  height: 100%;
}
