@keyframes blinking {
  0% { background-color: #9b9b9d; }
  50% { background-color: #e6e7e8; }
  100% { background-color: transparent; }
}

.table {
  height: auto;
  position: relative;
}

.table.loading .chart {
  filter: blur(3px);
  opacity: .3;
  pointer-events: none;
}

.table.loading .spinner {
  display: flex;
}

.table.transparent {
  min-height: inherit;
}

.table.transparent .chart {
  background-color: transparent;
}

.table.transparent table {
  background-color: transparent !important;
}

.table.transparent table td {
  padding-left: 0 !important;
}

.table.transparent table td div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .form {
  align-items: center;
  display: flex;
  margin: -15px 0 0;
  width: 100%;
}

.table .form > div {
  width: 100% !important;
}

.table .form .icon {
  cursor: pointer;
}

.table .load {
  color: #5c6ae0 !important;
  cursor: pointer;
}

.table .load > div {
  display: inline !important;
}

.table .load:hover {
  opacity: .6;
}

.hoverMenu {
  background-color: #fff;
  border-radius: 3px;
  display: none;
  filter: drop-shadow(0 0 0.75rem #d5d5d5);
  margin-left: 110px;
  margin-top: -75px;
  padding: 7px 0;
  position: absolute;
  width: 155px;
  z-index: 1000;
}

.table .hoverRow {
  list-style-type: none;
  padding: 4px 10px;
}

.table .hoverRow:hover {
  background-color: #ebeaea;
}

.table .launch {
  color: #9e9e9e;
  font-size: 22px;
  height: 25px;
  padding: 0 !important;
  text-align: right;
  vertical-align: middle;
  width: 25px !important;
}

.table .spinner {
  align-items: center;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
}

.table.focused tbody {
  animation: blinking 1.2s;
}

.table .pager {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.table .pagerLeft {
  justify-content: flex-start;
  margin: .3rem 0;
}

.table tbody .actions {
  display: flex;
  opacity: 0;
}

.table tbody .actions:hover,
.table tbody tr:hover .actions {
  opacity: 1;
}


