.profile {
  width: 100%;
}

.profile .border {
  border: 1px solid #e6e7e8;
  margin: 15px 0 0;
}

.profile .border > div {
  padding: 10px !important;
}

.profile legend {
  font-size: 1.6rem;
  font-weight: 600;
}

.profile .section .h2 {
  padding-bottom: 24px;
}

.profile .section .value {
  width: 100%;
  &:not(.editable) >div:last-child { font-weight: 600; }
}

.profile .section .field {
  align-items: center;
  padding-bottom: 10px;

  .between { font-weight: 700; }
}

.profile .field .label {
  color: #9b9b9d;
  font-weight: 600;
}

.profile .field .checkbox {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 15px 8px 8px;
}

.profile .field .checkbox .boxes {
  display: flex;
  margin: 0 0 0 15px;
  min-width: 100px;
  width: 45% !important;
}

.profile .value.editable .text {
  align-items: center;
  border-bottom: 1px solid #e6e7e8;
  display: flex;
  flex-direction: row;
}

.profile .complexityFields .selection {
  align-items: center;
  background-color: #e6e7e8;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  min-height: 4rem;
  > fieldset { width: 80%; }
}

.profile .complexityFields .field {
  padding-left: 1rem;
  padding-right: 1rem;
}

.profile .actions {
  text-align: right;
}

.profile .actions button div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
}

.profile .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 25px !important;
  line-height: 25px !important;
  div { height: auto !important; }
}

.profile .button button span {
  color: #fff !important;
  font-family: 'Montserrat' !important;
}

.profile .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.profile .chip .avatar {
  background-color: #5c6ae0 !important;
  color: #fff !important;
}

.profile .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.profile .dropdown ~ .icon {
  pointer-events: none !important;
  right: 35px !important;
  top: 8px !important;
}

.profile .dropdown > div > div {
  color: #9b9b9d !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}
