$background-fade: rgba(255, 255, 255, .54);

.header {
  background-color: #f7f7f8;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24);
  padding: 0 !important;
  width: 100%;
}

.header .left {
  display: inherit !important;
  .hover:hover > div > span { color: #000 !important; }
}

.header .brand {
  align-items: center;
  display: flex;
  &:hover { opacity: .6; }
}

.header .controls.left {
  align-items: center;
  display: flex !important;
  gap: 10px;
  left: 0;
  position: absolute;
}

.header .controls.right {
  align-items: center;
  display: flex;
  position: absolute !important;
  right: 2rem !important;
}

.header .controls .flow img {
  height: 20px;
}

.header .controls .profile {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.header .controls .profile ~ .icon {
  color: #fff !important;
  pointer-events: none !important;
  right: 35px !important;
}

.header .controls .profile > div > div {
  color: #fff !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.header .controls .translate {
  margin: 0 0 0 7rem;
}

.header .dates {
  padding: 50px;
}

.header .dropdown {
  height: 65px;
  position: absolute;
  top: 0;
  width: 100%;
}

.header .dropdown .icon {
  color: #5c6ae0 !important;
  font-size: 3.2rem !important;
}

.header .dropdown.active .list {
  opacity: 1;
  visibility: visible;
}

.header .dropdown.active .wrapper {
  opacity: 1;
  pointer-events: auto;
  transition: all .3s ease-in-out;
  visibility: visible;
  &::before { opacity: 1; }
}

.header .dropdown .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 14px 45px rgba(0, 0, 0, .25), 0 10px 18px rgba(0, 0, 0, .22);
  left: 0;
  opacity: 0;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  transition: all .3s;
  visibility: hidden;
  width: 100%;
}

.header .dropdown .list >div:hover {
  .primary { color: #000 !important; }
  .icon { color: #000 !important; }
}

.header .dropdown .primary {
  color: #5c6ae0 !important;
}

.header .dropdown .secondary {
  color: #9b9b9d !important;
  overflow: visible !important;
  white-space: normal !important;
}

.header .dropdown .wrapper {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 45px;
  transform: translateZ(0);
  transition: all .3s ease-in-out;
  visibility: hidden;
  width: 350px;
  z-index: 1;
}

.header .dropdown .wrapper::before {
  border: 12px solid transparent;
  border-bottom-color: #fff;
  bottom: 100%;
  content: '';
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  right: auto;
  transform: translateX(-50%);
  transition: opacity .3s;
  width: 0;
  z-index: 1;
}

.header .icon {
  color: #9b9b9d !important;
  cursor: pointer;
}

.header .icon.rotate {
  transform: rotate(90deg);
}

.header .icon-brand svg {
  cursor: pointer;
  display: none;
  fill: #000;
  height: auto;
  margin: 0 0 0 -10px;
  width: 120px;
}

.header .icon-flame svg {
  cursor: pointer;
  fill: #5c6ae0;
  height: auto;
  margin: 0 0 0 15px;
  width: 8px;
}

.header .nav {
  // box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23);
  z-index: 1;
}

.header .new::before {
  background-color: #5c6ae0;
  border-radius: 30px;
  content: '';
  display: inline-block;
  margin: 12px 0 0 -3px;
  padding: 5px;
  position: absolute;
}

.header .search {
  align-items: center;
  background-color: #f7f7f8;
  display: flex;
  justify-content: space-between;
  min-height: 75px;
}

.header .search >div:nth-child(3) {
  min-width: 150px !important;
}

.header .subnav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  min-height: 65px;
  overflow: hidden;
  padding: 10px 0;
}

.header .subnav .action {
  position: absolute;
  right: 0;
}

.header .subnav .button {
  border-radius: 5px !important;
  color: #2a2c3c !important;
  height: 25px !important;
  line-height: 25px !important;
}

.header .subnav .checkbox,
.header .subnav .back {
  left: 0;
  position: absolute !important;
  + div { padding: 0 30px 0 0 !important; }
}

.header .subnav .hover:hover > div > span {
  color: #000 !important;
}

.header .subnav .button:hover {
  background-color: #e5e7fa !important;
}

.header .subnav .button.active {
  background-color: #5c6ae0 !important;
  color: #fff !important;
  .icon { color: #fff !important; }
}

.header .subnav .icon.active {
  color: #5c6ae0 !important;
}

.header .subnav .total {
  align-items: center;
  color: #9b9b9d;
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.header .subnav.comp {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.header .subnav.comp >div:first-child {
  align-items: center;
}

.header .subnav.comp .title {
  color: #000;
  font-family: 'Rajdhani', 'Open Sans';
  font-size: 2.4rem;
  margin: 0 auto;
  max-width: inherit;
  overflow: hidden;
  padding-right: 2.4rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.header .subnav.comp .title.show {
  opacity: 1;
}

.header .subnav.comp .title .icon {
  color: #000 !important;
  margin: 0 10px 0 0;
}

.header .subnav .subnav-dropdown-container {
  padding: 0 10px;
  position: relative;
}

.header .subnav .subnav-dropdown-container button {
  border: 1px solid #9b9b9d !important;
}

.header .subnav .subnav-dropdown {
  height: 65px;
  min-width: 100%;
  position: absolute;
  top: 0;
}

.header .subnav .subnav-dropdown .icon {
  color: #5c6ae0 !important;
  // font-size: 3.2rem !important;
}

.header .subnav .subnav-dropdown-container:hover .list {
  opacity: 1;
  visibility: visible;
}

.header .subnav .subnav-dropdown-container:hover .subnav-dropdown-wrapper {
  opacity: 1;
  pointer-events: auto;
  transition: all .3s ease-in-out;
  visibility: visible;
  &::before { opacity: 1; }
}

.header .subnav .subnav-dropdown .list {
  background-color: #e6e7e8;
  border-radius: 5px;
  box-shadow: 0 14px 45px rgba(0, 0, 0, .25), 0 10px 18px rgba(0, 0, 0, .22);
  left: 0;
  min-width: 100%;
  opacity: 0;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  transition: all .3s;
  visibility: hidden;
}

.header .subnav .subnav-dropdown .list >div:hover {
  .icon { color: #000 !important; }
}

.header .subnav .subnav-dropdown .primary {
  color: #1a1b33 !important;
  font-weight: 700 !important;
}

.header .subnav .subnav-dropdown .secondary {
  color: #1a1b33 !important;
  font-weight: inherit !important;
  overflow: visible !important;
  white-space: normal !important;
}

.header .subnav .subnav-dropdown-wrapper {
  left: 0;
  min-width: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 45px;
  transform: translateZ(0);
  transition: all .3s ease-in-out;
  visibility: hidden;
  z-index: 1;
}

.header .subnav .subnav-dropdown-wrapper::before {
  border: 12px solid transparent;
  border-bottom-color: #e6e7e8;
  bottom: 100%;
  content: '';
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  right: auto;
  transform: translateX(-50%);
  transition: opacity .3s;
  width: 0;
  z-index: 1;
}

.header .subnav .expanded .primary {
  color: #5c6ae0 !important;
  font-weight: 700 !important;
}

.header .subnav .controls.alerting.owner {
  font-weight: 600;
  right: 250px !important;
}

.header .subnav .controls.alerting .selection {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.header .subnav .controls.alerting .selection ~ .icon {
  color: #fff !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 8px;
}

.header .subnav .controls.alerting .selection > div > div {
  color: #fff !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.header .tab {
  min-width: 130px !important;
  padding: 0 10px;
  &.search-btn >div>div { margin: 0 0 0 15px; }
  >div>div { height: 60px !important; }
}

.header .tab .icon {
  left: 15px !important;
  position: absolute !important;
}

.header .table {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header .tabs .active > div > div {
  color: #5c6ae0 !important;
  > span { color: #5c6ae0 !important; }
}

.header .tabs button > div > div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
  font-weight: 600;
}

.header .tabs button:hover > div > div {
  color: #000 !important;
}

.header .tabs .hover > div > div {
  color: #000 !important;
}

.header .tabs > div:first-child {
  background-color: #fff !important;
  color: #5c6ae0 !important;
}

.header .tabs > div:nth-child(2) > div {
  background-color: #5c6ae0 !important;
  height: 4px !important;
}

.accept {
  color: #5c6ae0 !important;

  &[disabled],
  &:disabled { opacity: 0.3; }
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.item .icon {
  height: 16px !important;
  width: 16px !important;
}

.header .customize {
  padding-right: 2rem;
  button { height: 37px !important; }
}

.header .customize button:first-of-type {
  padding-bottom: 3px !important;
}

.header .customize button:last-of-type {
  padding-top: 3px !important;
}

.header .customize button > div > span {
  color: #fff !important;
}

.settings > div > div > div > div {
  overflow-y: auto !important;
}

.settings .delete {
  left: 5px;
  position: absolute !important;
}

.customize .hover:hover > div > span {
  color: #9b9b9d !important;
}

.overlay {
  background-color: $background-fade !important;
}

.popover .table {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.popover .table > div {
  margin: 0 -10px 0 0;
  padding: 0 0 0 15px;
  width: 45%;
}

@media(min-width: 1024px) {
  .header .icon-brand svg { display: inline-block; }
  .header .search >div:nth-child(1) { display: block; }
}

@media screen and (max-width: 1024px) {
  .expanded {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .header .controls.right,
  .header .controls.left {
    left: 0 !important;
    margin: .5rem 0;
    position: static !important;
    right: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .header .subnav {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 16px;
  }

  .header .subnav.comp {
    justify-content: center;
  }

  .header .subnav.comp>div:first-child {
    align-items: flex-start;
  }
}

@media print {
  .header {
    display: none;
  }
}

// If the header has a subnav, the sidebar needs to adjust for the extra height
.header.has-subnav + div > div {
  top: 135px !important;
}
