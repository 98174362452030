.media-grid-video {
  cursor: pointer;
  margin: 2px;
  position: relative;
  // > div { position: relative; }
}

.media-grid-video video {
  backface-visibility: hidden;
  display: block;
  opacity: 1;
  transition: .5s ease;
  &:focus { outline: none !important; }
}

.media-grid-video .overlay {
  height: calc(100% - 75px);
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  transition: .5s ease;
  width: 100%;
  > div { height: 100%; }
}

.media-grid-video:hover .overlay {
  opacity: 1;
}
