.bulk-edit-button .button {
  padding: 0;
}

.bulk-edit-button button {
  background-color: #5c6ae0 !important;
  span { color: #fff !important; }
}

.bulk-edit-button button .badge {
  padding-top: 3px !important;
}

.bulk-edit-button button .badge > span:nth-child(2) {
  height: 15px !important;
  right: 14px !important;
  top: 7px !important;
  width: 15px !important;
}
