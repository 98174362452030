.tab {
  width: 100%;
}

.tab .body {
  padding: 35px 0 0;
}

.tab .tabs {
  padding-top: .5rem;
}

.tab .tabs .active > div > div {
  color: #18191c !important;
}

.tab .tabs button {
  padding: 5px 0 0 !important;
}

.tab .tabs button > div > div {
  color: #9b9b9d !important;
  font-family: 'Montserrat';
  font-weight: 600;
  max-height: 20px;
}

.tab .tabs > div:first-child {
  background-color: #fff !important;
  color: #5c6ae0 !important;
}

.tab .tabs > div:nth-child(2) > div {
  background-color: #fff !important;
  height: 3px !important;
}
