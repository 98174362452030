$background-fade: rgba(255, 255, 255, .54);

.base {
  padding: 15px 0;
  width: 950px;
}

.base .button button {
  height: 25px !important;
  line-height: 25px !important;
  span { font-family: 'Montserrat' !important; }
}

.base .card {
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important;
  font-size: 1.6rem;
  margin: 15px 0 25px;
  padding: 25px;
}

.base .chips {
  display: flex;
  flex-wrap: wrap;
}

.base .chip {
  text-transform: capitalize !important;
}

.base .chip .avatar {
  background-color: #5c6ae0 !important;
  color: #fff !important;
}

.base .dropdown {
  background-color: #d5dbea !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.base .dropdown ~ .icon {
  pointer-events: none !important;
  right: 35px !important;
}

.base .dropdown > div > div {
  color: #8e96b1 !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}

.base .geo {
  position: relative !important;
  top: 5px;
}

.base .header .h4 + .h4 {
  padding: 0 5px;
}

.base .header > div {
  align-items: center;
  display: flex;
}

.base .header .help {
  height: 30px !important;
  left: -5px;
  top: -5px;
  width: 30px !important;
}

.base .header .help .icon {
  color: #8e96b1 !important;
}

.base .header .title .icon {
  color: #000 !important;
  margin: 0 5px 0 -30px !important;
  padding: 0 10px 0 0;
}

.base .hero {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 15px 0 0;
}

.base .hero .h0 {
  display: inline-block;
  font-size: 3.6rem !important;
  padding: 15px 0 0;
}

.base .inline {
  align-items: center;
  display: flex;
  height: 35px;
  justify-content: flex-start;
  >div:first-child { padding: 0 15px 0 0; }
}

.base .item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}

.base .map {
  margin: -25px 0 0 -25px;
}

.base .table {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  word-break: break-word;
  >div:first-child { min-width: 200px; }
  >div:nth-child(2) { word-break: break-word; }
  .top { align-self: flex-start; }
}

.base .table a {
  color: #5c6ae0;
  display: block;
  text-decoration: none;
}


.base .pii .body {
  border: 1px solid #e5e7fa;
  border-radius: 5px;
  margin: 15px 0 0;
  padding: 15px;
  width: 600px;
  word-wrap: break-word;
}

.base .pii .warning {
  display: flex;
}

.base .pii .warning p {
  margin-top: 0;
}

.base :global .highlight {
  word-break: break-word;
}

.item.active > div > div {
  color: #000 !important;
  font-weight: 700 !important;
}
