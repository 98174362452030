@import './base.module';

.datefilter {
  margin: 0 0 50px;
  overflow-x: hidden;
  padding: 0;
}

.datefilter.inline {
  margin: 0;
}

.datefilter.inline button span {
  font-size: 1.8rem;
}

.datefilter .listing .icon {
  left: 0 !important;
  top: -3px !important;
}

.datefilter .listing > div > div {
  padding: 10px 16px 10px 40px !important;
}
