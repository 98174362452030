@import '../base.module';

.taxii .a + .a {
  padding: 0 15px;
}

.taxii .api {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}

.taxii .api > div {
  align-items: center;
  display: flex;
  margin: 5px;
}

.taxii .card .h2 {
  padding: 0 0 15px;
}

.taxii .table >div:first-child {
  max-width: 200px;
}

.tokens .table .help {
  font-size: 1.8rem !important;
  padding: 0 5px;
  top: 2px;
}

.taxii .input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  >div:first-child { min-width: 75%; }
  .button { box-shadow: none !important; }
}

.taxii .token {
  background-color: #e6e7e8;
  margin: 15px 0;
  padding: 15px;
  word-break: break-all;
}

.taxii .token .icon {
  color: #5c6ae0;
  margin: 0 0 0 5px;
}

.dialog .subtitle {
  font-size: 1.6rem;
  padding-bottom: 10px;
}

.dialog .info {
  padding-left: 5px;
}
