$img-width: 50rem;
$width: 125rem;

.report {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $width;
  padding: 0 !important;
  transition: all 0.5s;
}

.report .breakall {
  word-break: break-all;
}

.report > div > div > div {
  width: 100%;
}

.report .header {
  display: flex;
  padding: 20px;
}

.report .header .watermark {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.report .draft {
  display: flex;
  font-weight: 700;
}

.report .draft > div {
  margin: 0 auto;
}

.report .header > div:nth-child(1) {
  margin: 0 auto;
}

.report img {
  height: auto;
  max-height: 770px;
  max-width: $img-width !important;
  object-fit: contain;
}

.report .card {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 1.6rem;
  margin: 25px 0;
  text-align: center;
  width: auto;
}

.report .card .body {
  padding: 35px 0;
  text-align: left;
  word-break: break-word;
}

.report .card .body p,
.report .card .body ul,
.report .card .body ol {
  font-size: 1.6rem;
  margin: 0 auto;
  max-width: $width !important;
}

.report .card .body:not(.google) p,
.report .card .body ul,
.report .card .body ol {
  font-weight: 400;
}

.report .card .h2 {
  margin: 3.5rem 0;
}

.google h1,
.google h2,
.google h3,
.google h5 {
  background-color: transparent !important;
  margin: 0 auto !important;
  max-width: $width;
}

.google h1 span,
.google h2 span,
.google h3 span,
.google h5 span {
  font-family: 'Libre Franklin' !important;
}

.google h3 {
  padding-bottom: 24px !important;
}

.google h1 span {
  color: #5c6ae0 !important;
  font-family: 'Libre Franklin' !important;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 900 !important;
  text-decoration: none;
  vertical-align: baseline;
}

.google h2 span {
  color: #000 !important;
  font-family: 'Libre Franklin' !important;
  font-size: 1.8rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  vertical-align: baseline !important;

  a {
    color: #000 !important;
  }
}

.report .card .body > div > div:first-of-type {
  max-width: inherit;
  padding: inherit;
}

.report .card .body ul,
.report .card .body ol {
  list-style-type: none;
  padding: 0 1px 0 15px;
}

.report .card .body ol {
  padding: 0;
}

.report .card .body li {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 5px 20px 0;
  position: relative;
  text-align: left;
}

.report .card .google li {
  color: #000;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  font-weight: 300 !important;
  padding: 5px 20px 0;
  position: relative;
  text-align: left;
}

.report .card .body li strong {
  background-color: #e6e7e8;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 0 -10px;
  padding: 5px 10px;
}

.report .card .body li::before {
  color: #5c6ae0;
  content: '•';
  font-size: 2.4em;
  left: 0;
  padding: 0 10px 0 0;
  position: absolute;
  top: -8px;
}

.report .card .body li span {
  word-break: break-word;
}

.report .card .body :global .changed {
  color: #674ea7 !important;
  font-weight: bold !important;
}

.report .card .body.standup {
  padding-top: 0;
}

.report .card .body.standup > div {
  color: #000;
  margin: 0 auto;
  max-width: $width;
}

.report .card .body.standup a {
  text-decoration: none;
}

.report .card .body.standup h3 {
  color: #000 !important;
  font-size: 2rem !important;
  padding-top: 35px;
}

.report .card .body.standup h3 a {
  font-size: 2rem;
}

.report .card .body.standup ul {
  padding: 0;
}

.report .card .body.standup li {
  padding: 15px 0 0;
}

.report .card .body.standup li p {
  color: #000;
}

.report .card .body.standup p {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

.report .card .body.standup p strong {
  padding: 0;
}

.report .card .body.standup p:first-of-type strong {
  font-size: 1.6rem;
}

.report .card .body.standup li::before {
  content: '';
  padding: 0 !important;
}

.report .card .body.standup li strong {
  margin: 0 5px 0 0;
}

.report .card .body.standup strong {
  display: inline-block;
}

.report .card .body :global .image {
  max-height: 770px;
  max-width: $img-width !important;
  object-fit: contain;
}

.report .card .body :global .image,
.report .card .body :global .caption {
  margin: 0 auto;
  padding: 0;
}

.report .card a {
  color: #5c6ae0;

  &:hover {
    color: #3547d9;
  }
}

.report .card strong {
  color: #000;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.report .card p {
  padding: 15px 0 0;
  text-align: left;
}

.report .card .google p {
  background-color: transparent !important;
  padding: 0;
}

.report .card .google li h6 {
  padding: 0;
}

.report .card .google h6 {
  background-color: transparent !important;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 300;
  margin: auto;
  padding: 0;
  width: 400px;
}

.report .card .google h6 span {
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 300;
  margin: 0 auto;
  max-width: $width;
  padding-bottom: 0;
}

.report .card .google p span,
.report .card .google ul li span {
  color: #000;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 1.4;
  text-decoration: none;
  vertical-align: baseline;
}

.report .card .google img {
  cursor: pointer;
  max-height: 770px;
  max-width: $img-width !important;
  object-fit: contain;
}

.report .card p strong {
  display: block;
  font-family: 'Libre Franklin' !important;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 25px 0 0;
}

.report .card .body:not(.google) p:first-of-type,
.report .card p:first-of-type strong {
  font-family: 'Libre Franklin' !important;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0;
}

.report .card :global .footnote {
  color: #000;
  font-size: 1rem;
  line-height: 1.4rem;
}

.report .card :global .image {
  cursor: zoom-in;
  font-size: 1rem;
  font-style: italic;
  text-align: center;
}

.report .card :global .image img {
  border: 0;
  cursor: zoom-in;
  height: auto !important;
  margin: 15px 0 0 !important;
  max-height: 770px;
  max-width: $img-width !important;
  object-fit: contain;
}

.report .card :global .image > br {
  content: '';
  display: block;
  margin: 0;
}

.report .card :global .caption {
  color: #000;
  font-size: 1rem;
  font-style: italic;
  padding: 0;
  text-align: center;
}

.report .card :global .caption strong,
.report .card :global .image strong {
  color: #000;
  display: inline-block;
  font-size: 1rem;
  font-style: italic;
  margin: -15px 0 0;
  padding: 25px 0 0;
  text-transform: none;
  width: 100%;
}

.report .card :global .quote {
  color: #000;
  margin: 0 !important;
  padding: 15px 0 0 35px;
}

.report .card :global .label {
  border: 0;
  color: #000 !important;
  display: flex;
  font-weight: 700 !important;
  justify-content: space-between;
  letter-spacing: 0.5px;
  min-width: 40%;
  padding: 0 15px 0 0;
  text-transform: uppercase;
}

.report .card :global .row {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: $width;
}

.report .card table {
  table-layout: fixed;
  width: 100%;
}

.report .google table,
.report .google th,
.report .google td {
  background-color: transparent;
  border-collapse: collapse;
  margin: 0 auto;
  max-width: $width;
  min-width: 100%;
}

.report .google table tr:first-child {
  background-color: #ddd !important;
}

.report .google table tr:first-child p span {
  color: #000 !important;
}

.report .google table tr:first-child h5 span {
  color: #000 !important;
}

.report .google table tr td {
  border: 1px solid #000;
  padding: inherit !important;
  vertical-align: baseline !important;
}

.report .google table tr {
  align-items: initial !important;
  border: 1px solid #e6e7e8;
  display: table-row !important;
  justify-content: initial !important;
}

.report .card .google table tr p {
  background-color: transparent !important;
  padding: 5px !important;
}

.report .card :global table span {
  font-size: 1.6rem !important;
}

.report .card :global table tr {
  align-items: center;
  border: 1px solid #e6e7e8;
  display: flex;
  justify-content: flex-start;
}

.report .card :global table tr td {
  padding: 5px 35px 5px 5px;
}

.report .card :global table tr p {
  font-weight: 300 !important;
  padding: 0;
}

.report .chips {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 0;
}

.report .chip {
  background-color: #5c6ae0 !important;
  color: #fff;
  cursor: pointer !important;
  margin: 0 10px 10px 0 !important;
  text-transform: capitalize !important;

  button {
    color: #5c6ae0 !important;
  }

  span {
    color: #fff !important;
  }

  svg {
    fill: #fff !important;
  }

  :hover {
    opacity: 0.8;
  }

  > span {
    padding-left: 5px !important;
  }
}

.report .chip .avatar {
  background-color: #5c6ae0 !important;
  color: #fff !important;
}

.report .footer {
  display: none;
  margin: 50px 0 0;
}

.report .footer > div:first-child {
  border-top: 3px solid #000;
  color: #000;
  font-size: 2.4rem;
  padding: 15px 0 0;
}

.report .icon svg {
  cursor: pointer;
  fill: #fff;
  height: auto;
  margin: 0 0 0 -10px;
  width: 135px;
}

.report .icon.flame {
  fill: #5c6ae0;
  height: auto;
  width: 8px;
}

.report .card .image {
  max-height: 770px;
  max-width: $img-width;
  object-fit: cover;
}

.report .related {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.report .related .h2 {
  line-height: 1.4rem;
}

.report .related .h4 {
  padding: 0 0 15px;
}

.report .header .h4 {
  margin-left: 30px;
}

.report .related > a {
  max-width: 30%;
}

.report .related > div:hover {
  cursor: pointer;
  opacity: 0.6;
}

.report .related img {
  height: 135px;
  object-fit: cover;
  padding: 15px 0;
  width: 100%;
}

.report .sources .a {
  word-break: break-all;
}

.report .sources .icon {
  color: #5c6ae0 !important;
  padding: 0 !important;
  top: 5px;
  width: 30px !important;
}

.report .sources .icon.hidden {
  display: none !important;
}

.report .sources ol {
  color: #000 !important;
  -moz-padding-start: 20px;
  -webkit-padding-start: 20px;
}

.report .sources ol li {
  min-height: 32px;
  padding: 5px 0 0 15px;
  text-align: left;
  word-break: break-all;
}

.report .title {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
}

.report .title .h0 {
  max-width: $width;
}

.report .title .icon {
  color: #000 !important;
  margin: 0 5px;
}

.report .card .google.body.standup {
  padding-top: 0;
}

.report .card .google.body.standup > div {
  color: #000;
  margin: 0 auto;
  max-width: $width;
}

.report .card .google.body.standup a {
  text-decoration: none;
}

.report .card .google.body.standup h3 {
  padding-top: 35px;
}

.report .card .google.body.standup h3 span {
  color: #000 !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  padding-top: 35px;
}

.report .card .google.body.standup h3 span a {
  color: #5c6ae0 !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  padding-top: 35px;
}

.report .card .google.body.standup h1 a {
  font-size: 2rem !important;
  text-decoration: none;
}

.report .card .google.body.standup h1 span {
  font-size: 2rem !important;
  font-weight: 400;
}

.report .card .google.body.standup ul {
  padding: 0;
}

.report .card .google.body.standup ul li {
  font-size: 1.4rem !important;
  margin-left: 0;
  padding: 15px 0 0;
}

.report .card .google.body.standup ul li span {
  color: #000;
  font-size: 1.4rem !important;
  line-height: 1.5;
}

.report .card .google.body.standup li p {
  color: #000;
}

.report .card .google.body.standup p {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

.report .card .google.body.standup p:first-of-type strong {
  font-size: 1.6rem;
}

.report .card .google.body.standup li::before {
  content: '';
  padding: 0 !important;
}

.report .card .google.body.standup li strong {
  margin: 0 5px 0 0;
}

.report .card .google.body.standup strong {
  display: inline-block;
}

@media print {
  .report,
  .report > div,
  .report .body,
  .report .card {
    display: block !important;
    width: 100%;
  }

  .report .card {
    margin: 0;
  }

  .report .card table tr td:last-child {
    min-width: 350px;
  }

  .report .header .watermark {
    display: block;
  }

  .report [data-html2canvas-ignore] {
    display: none !important;
  }

  :global [class^='_pendo'] {
    display: none !important;
  }

  :global [class^='bentobox_'] {
    display: none !important;
  }

  :global [class*='MuiSnackbar-root'] {
    display: none !important;
  }
}
