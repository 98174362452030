.fraudwatch .list {
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
}

.fraudwatch .button.disabled {
  cursor: not-allowed;
}

.avatar {
  min-width: 80px !important;
}

.label {
  max-width: 360px;
}

.label p {
  margin-right: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
