@import './base.module';

.phash {
  background-color: #f7f5f5;
  border: 4px dashed #9b9b9d;
  padding: 0 !important;
  > div { margin: 0 !important; }
}

.phash div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
  width: 100%;
  &:focus { outline: none; }
}

.phash .disclaimer {
  color: #303137;
  font-size: 1.2rem;
  margin: 0 24px 10px;
  max-width: 350px;
}
