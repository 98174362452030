@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin step-fade-in($count) {
  @for $i from 1 through $count {
    &:nth-of-type(#{$i}) {
      animation: fade-in 0.25s linear;
      animation-delay: (#{$i * 0.1s});
      animation-fill-mode: forwards;
      display: block;
      opacity: 0;
    }
  }
}

.wiki {
  padding: 0;
}

.wiki .controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.wiki .controls .close {
  cursor: pointer;
  position: absolute !important;
  right: 80px;
}

.wiki .empty {
  display: flex;
  height: 100%;
  width: 100%;
}

.wiki .filter {
  display: flex;
  justify-content: space-between;
}

.wiki .filterDropdown {
  padding-right: 10px;
  width: 150px;
}

.wiki .formControl {
  padding-top: 8px;
  width: 300px;
}

.wiki .grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 8px;
  padding: 0 0 50px;
}

.wiki .grid .card {
  margin: 10px;
  position: relative;
}

.wiki .grid .footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0;
  z-index: 1;
}

.wiki .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.wiki .header .title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
}

.wiki .header a {
  color: #000;
  text-decoration: underline;
}

.wiki .header .icon:hover {
  opacity: 0.6;
}

.wiki .load circle {
  opacity: 0 !important;
  stroke: #fff !important;
}

.wiki .load.loading circle {
  opacity: 1 !important;
}

.wiki .list a {
  @include step-fade-in(10);
}

.wiki .multiselect {
  padding: 8px 10px 0 0;
}

.wiki .progress {
  margin: 15px 0 0;
}

.wiki .textFilter {
  display: flex;
  justify-content: flex-end !important;
}

.wiki .select {
  width: 200px;
}
