@import 'base.module';

.syntax .body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.syntax .body pre {
  display: inline-block;
}
