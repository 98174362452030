@import './base.module';

.ccmc {
  max-width: 950px;
}

.ccmc .monitoring {
  font-weight: 600;
  margin-bottom: -10px;
  text-align: right;
}

.ccmc .monitoring > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  span { margin-right: 5px; }
  > div { display: flex; }
}

.ccmc .tabs > div:first-of-type {
  background-color: transparent !important;
  button { color: #79797b !important; }
}

.ccmc .tabs button:disabled {
  cursor: no-drop !important;
}

.ccmc .body {
  min-width: 375px;
  overflow: auto;
  padding: 24px;
  position: relative;
  text-overflow: ellipsis;

  > div:first-of-type { max-width: 700px; }
}

.ccmc .body .row.line {
  border-bottom: 1px solid #9b9b9d;
  margin-bottom: 24px;
  padding-bottom: 0 !important;
}

.ccmc .body .label {
  color: #79797b;
  font-family: 'Rajdhani';
  font-size: 1.2em;
}

.ccmc .body .help {
  color: #9b9b9d;
  font-size: 1.4rem;
}

.ccmc .body .error {
  color: rgb(244, 67, 54);
  font-size: 12px;
  line-height: 12px;
}

.ccmc .body .add,
.ccmc .body .delete {
  height: 32px !important;
  margin-left: 10px;
}

.ccmc .body .add button,
.ccmc .body .delete button {
  background-color: #5c6ae0 !important;
  height: 32px !important;
  width: 32px !important;
}

.ccmc .body .add button span,
.ccmc .body .delete button span {
  color: #fff !important;
  height: 32px !important;
  line-height: 32px !important;
}

.ccmc .body .horizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.ccmc .body .horizontal > div {
  padding-right: 24px !important;
  white-space: nowrap;
  width: auto !important;
}

.ccmc .body .actions {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
}

.ccmc .body .button-container {
  cursor: default !important;
  display: flex !important;
  justify-content: flex-end !important;
  &.center { justify-content: center !important; }
}

.ccmc .body .center {
  text-align: center;
}

.ccmc .body .actions .test {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  .button { margin-top: 5px; }
}

.ccmc .body .actions .button button:enabled,
.ccmc .body .button-container .button button:enabled {
  background-color: #5c6ae0 !important;
  span { color: #fff !important; }
}

.ccmc .body .progress {
  bottom: -24px;
  left: 0;
  position: absolute;
  width: 100%;
}
