@import './base.module';

.glossary .h0 {
  padding: 50px 0 0;
}

.glossary .card .h2 {
  padding: 0 0 15px;
  text-transform: none !important;
}

.glossary .header {
  background-color: #f7f5f5;
  height: 45px;
  margin-top: -25px;
  position: sticky;
  top: -10px;
}

.glossary .header > div {
  height: 100%;
  justify-content: center;
}


.glossary a {
  color: #5c6ae0 !important;
  text-decoration: none !important;
}
