.pie.mini {
  margin: -15px 0 0 -15px;
  position: relative;
  z-index: 0;
}

.pie.mini .cap {
  margin: -65px 0 0 0;
}

.pie .cap {
  color: #8e96b1 !important;
  font-size: 1.2rem;
  min-height: 15px;
  padding: 0;
  text-align: center;
}

.pie .chart ul li {
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0;
  overflow: hidden;
  padding: 0 0 0 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pie .chart ul span {
  color: #5c6ae0 !important;
  text-decoration: underline;
}

.pie .chart ul li:hover span {
  opacity: .6;
}

.pie .chart svg > g > g > g > g:hover path {
  cursor: pointer;
  fill: #5c6ae0;
}

.pie .tooltip {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
}

.pie :global .recharts-tooltip-wrapper {
  z-index: 200;
}

.pie :global .recharts-pie {
  cursor: pointer;
}
