.ransomware {
  display: flex;
  height: 100%;
}

.ransomware .header {
  margin: 35px 0;
}

.ransomware .title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.ransomware .title .icon {
  color: #000 !important;
  margin: 0 0 0 -30px;
  width: 30px;
}

.ransomware .wrap {
  max-width: 750px;
  overflow-wrap: break-word;
}

.ransomware .carousel {
  width: 100%;
}
