.histogram {
  padding: 0;
  position: relative;
}

.histogram .spinner {
  left: 50%;
  position: absolute;
  top: 35%;
}

.histogram .container {
  position: relative;
}

.histogram .container.loading {
  opacity: 0.3;
  pointer-events: none;
}

.histogram .table {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 35px;
  top: 15px;
  >div >div:nth-child(2) { word-break: break-word; }
}

.histogram .table > div > div:first-child {
  flex-grow: 1;
  max-width: 200px;
}

.histogram .dropdown {
  background-color: #e5e5e6 !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24);
  height: 28px !important;
  left: 21px !important;
  line-height: 28px !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.icon) { text-transform: capitalize !important; }
}

.histogram .dropdown ~ .icon {
  pointer-events: none !important;
  right: 14px !important;
  top: 8px !important;
}

.histogram .dropdown > div > div {
  color: #a4a4a5 !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 6px !important;
}
