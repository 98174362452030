$background-fade: rgba(255, 255, 255, .54);
$nav-width: 175px;

.home {
  padding-left: calc($nav-width + 2rem);
}

.home .bentobox {
  --t-h6-size: 1.4rem;
  --t-lbl-sm-size: 1.4rem;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 10px;
  top: 25px;
  z-index: 1203;
}

.home .bentobox [class*='iconbutton-module'] {
  margin: 0 5px;
  transform: scale(1.25);
} 

.home .content {
  min-height: 100vh;
  padding: 35px 0 50px;
  position: relative;
  top: 120px;
}

.home .content.flow {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0;
  top: 0;
}

.home .header .sticky {
  left: $nav-width;
  position: fixed;
  top: 0;
  transform: translate3d(0);
  transition: transform .5s;
  width: calc(100% - $nav-width);
  z-index: 100;
}

.home .loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.home .notification {
  background-color: #5c6ae0;
  color: #fff;
  padding: 10px 35px 10px 10px;
}

.home .notification .icon {
  color: #fff;
  margin: -25px 10px 0 0;
  position: absolute;
  right: 0;
}

.overlay {
  background-color: $background-fade !important;
}

.overlayIgnite [class*='MuiPaper-root'] {
  border: 8px solid #e3e3e3;
}

.overlayIgnite .overlaytitle {
  align-items: center;
  color: #000 !important;
  display: flex;
  justify-content: flex-end;
}

.overlayIgnite .overlaycontent {
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 0 50px 50px;
  text-align: center;
  text-transform: inherit;
}

.overlayIgnite .button {
  margin: 0 100px;
  padding: var(--spacing-500);
}

.overlayIgnite .textbutton {
  color: #000;
  font-size: 24px;
  margin: 0 100px;
  text-transform: inherit;
}

.dialog {
  z-index: 300 !important;
}

.dialog > div > div {
  max-width: 75% !important;
  width: 75% !important;
  h3 { margin: 0 0 0 -10px !important; }
}

.dialog .content {
  overflow-x: hidden;
  padding: 0 !important;
}

@media print {
  .home {
    background-color: #fff;
    margin: 35px;
    padding: 0;
  }

  .home .content {
    display: block !important;
    padding: 0 !important;
    top: 0 !important;
    transform: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .home .content {
    top: 140px;
  }
}
