.context-menu,
.sub-menu {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  color: #303137;
  font-size: 16px;
  min-width: 160px;
  padding: 0;
  pointer-events: none;
  transition: opacity .25s ease !important;
}

.sub-menu {
  display: none;
  pointer-events: inherit;
  z-index: -1;
}

.context-menu .item {
  background: 0 0 !important;
  border: 0 !important;
  color: #303137 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  padding: 10px 20px !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  > div > div { padding: 0 !important; }
}

.context-menu .item:hover {
  background-color: #1a1b33 !important;
  border-color: #1a1b33 !important;
  color: #fff !important;
  text-decoration: none !important;

  div { color: #fff !important; }
  .icon { color: #fff !important; }
}

.context-menu .item span {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

:global .react-contextmenu--visible {
  display: block;
  z-index: 1;
}
