.accordion {
  width: 100%;
}

.accordion .top {
  align-items: flex-end;
  border-bottom: 1px solid #1a1b33;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;

  .icon { color: #1a1b33 !important; }
}

.accordion .top .header {
  color: #1a1b33;
  font-size: 2.4rem;
  font-weight: 700;
  padding-bottom: 10px;
}

.accordion .top .subheader {
  color: #79797b;
  font-size: 1.8rem;
}

.accordion .content {
  padding: 24px 0;
}

.accordion .content.hide {
  display: none;
}
