.wrapper {
  display: flex;
  padding-right: 5px;
  position: relative;
}

.wrapper .triangle {
  border-color: transparent #5c6ae0 transparent transparent;
  border-style: solid;
  border-width: 0 7px 7px 0;
  position: absolute;
  right: 0;
  top: 0;
}
