$font-family: '"Open Sans"',
  'Arial',
  'sans-serif';

.table {
  border-radius: 5px !important;
  overflow: visible;
  padding: 0 0 35px;
}

.table .actions {
  display: flex;
}

.table .card {
  border-radius: 5px !important;
  margin: 0;
}

.table .card > div {
  overflow: hidden;
}

.table .chip {
  background-color: #5c6ae0 !important;
  margin: 10px 10px 10px 0 !important;
  text-transform: capitalize !important;
  button { color: #a2a9c2 !important; }
  span { color: #fff !important; }
  svg { fill: #fff !important; }
}

.table tbody .icon.color {
  display: inline-block !important;
  margin: 0 5px 0 0;
  min-height: 15px !important;
  min-width: 15px !important;
}

.table td {
  overflow: hidden;
}

.table td > a {
  display: block;
  margin: -20px;
  padding: 20px;
}

.table .image {
  background-color: #fff;
  border: 1px solid #bcc2d6;
  display: block;
  height: auto;
  height: 200px;
  margin: 15px 0;
  object-fit: scale-down;
  width: 200px;
  z-index: 3;
}

.table .images {
  align-items: center;
  display: flex;
}

.table .placeholders {
  align-items: center;
  display: flex;
}

.table .image.placeholder {
  background-color: #d5dbea;
  margin: 0;
  padding: 0;
}

.table .image.placeholder:nth-child(1) {
  margin-left: -150px;
  transform: scale(0.75);
  z-index: 2;
}

.table .image.placeholder:nth-child(2) {
  margin-left: -165px;
  transform: scale(0.65);
  z-index: 1;
}

.table .placeholder:nth-child(3) {
  margin-left: -25px;
  z-index: 0;
}

.table .hidden {
  pointer-events: none;
}

.table .hidden .image {
  filter: blur(30px);
}

.table .link {
  color: #5c6ae0 !important;
}

.table .link:hover {
  text-decoration: underline;
}

.icon {
  font-size: 2rem;
  width: 1em;
}

.hover:hover {
  cursor: pointer;
}

.hoverMenu {
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  display: none;
  filter: drop-shadow(0 0 0.75rem #d5dbea);
  margin-top: -95px;
  padding: 7px 0;
  position: absolute;
}

.table .hoverRow {
  cursor: pointer !important;
  list-style-type: none;
  padding: 4px 10px;
}

.table .hoverRowString {
  cursor: pointer !important;
}

.vulnDB {
  margin-left: 5px;
}

.table .hoverRow:hover {
  background-color: #eff0fb;
}

.table .launch {
  color: #a2a9c2;
  font-size: 22px;
  height: 25px;
  padding: 0 !important;
  text-align: right;
  vertical-align: middle;
  width: 25px !important;
}

.table .viewed a {
  color: #bcc2d6 !important;
}

.table .loading {
  color: #343949;
  font-size: 2rem;
}

.table .loading .source {
  font-weight: 700;
  padding: 0 24px;
}

.table .selectable-text-link *:not(div,img,:global .material-icons) {
  cursor: auto;
}

.table .listitem.ignite {
  background-color: #5c6ae0;
  border-radius: 3px;
  color: #fff !important;
  display: inline-flex;
  gap: var(--spacing-100);
  margin: 0 0 var(--spacing-400) 0;
  padding: var(--spacing-300) var(--spacing-200);
  width: auto;
}

.table .listitem.ignite.inline {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: var(--spacing-100);
  z-index: 9000;
}

.table .listitem.ignite .icon,
.table .listitem.ignite.inline.icon {
  color: #fff !important;
  cursor: pointer;
  font-size: 15px;
  margin: 0 var(--spacing-200);
  min-width: auto !important;
  position: absolute;
  width: auto !important;
  z-index: 0;
}

.table .listitem.ignite .textlink {
  padding: 0 var(--spacing-200);
  text-decoration: underline;
}

.table .listitem.ignite .textlink:hover {
  background-color: #5c6ae0;
}

// We use this class to make an otherwise block element selectable and inline.
// We use display: inline to keep highlightable elements as narrow as possible.
// That way, the blank space in the table still interacts as a clickable link would.
.table .selectable-text-link .selectable-text-inline {
  cursor: auto;
  display: inline;
}

.table .selectable-text-link {
  -moz-select: text;
  -ms-select: text;
  -webkit-user-select: text;
  user-select: text;
}

:global .react-images__footer--isModal {
  z-index: 0 !important;
}

:global .react-images__pager {
  margin: 0 350px 0 0;
}

:global .react-images__positioner {
  z-index: 1205 !important;
}

:global .react-images__header_button--fullscreen {
  display: none !important;
}

:global .react-images__track {
  position: relative;
  z-index: 2;
}

:global .react-images__track img {
  max-height: 85vh !important;
  /* stylelint-disable unit-whitelist */
  max-width: 50vw !important;
  padding: 0 0 0 50px;
}

:global .react-images__positioner .caption {
  display: flex;
  justify-content: flex-end;
  margin: -10px 0 0 0;
  width: 100%;
}

:global .react-images__positioner .caption button {
  margin: 0 10px;
}

:global .react-images__header,
:global .react-images__navigation button {
  display: none;
}

:global .icon {
  color: #5c6ae0;
  font-size: 1.4rem;
}

:global .icon svg {
  width: 1.4rem;
}

:global .translation {
  color: #a2a9c2;
  display: inline-block;
  font-size: 1.2rem;
  margin: .5rem 0 0;
}

@media (min-width: 1024px) {
  :global .react-images__track img {
    max-width: 100%;
    padding: 0;
  }
}
