@import './base.module';

.cves {
  margin-top: 128px;
}

.cves .card .h2 {
  padding: 0;
}

.cves .card .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cves .container {
  display: flex;
  justify-content: space-between;
}

.cves .container > div {
  width: 100%;
}

.cves .container > div:nth-child(2) {
  padding: 0 0 0 35px;
}

.cves .help {
  width: 5px;
}

.cves .help .icon {
  font-size: 1rem !important;
  width: 5px;
}

.cves .item {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  margin: 15px 0 0;
  max-width: 420px;
  padding: 15px;
  word-wrap: break-word;
}

.cves .table > div:nth-child(2) {
  overflow: hidden;
  word-break: normal;
}

.cves .table {
  padding: 15px 0;
}

.cves .share {
  align-items: end;
  width: 10% !important;
}
