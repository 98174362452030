@use 'sass:math'; // stylelint-disable-line at-rule-no-unknown

@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  $multiplier: 1;
  $current-width: 0;
  flex-basis: $flex-basis;
  flex-grow: 1;
  max-width: 100%;

  @while $current-width < $max-viewport-width { // stylelint-disable-line at-rule-no-unknown
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media (min-width: $flex-basis * $multiplier) {
      max-width: percentage(math.div(1, $multiplier));
    }
  }
}

.search-type-selection {
  position: relative;
}

.search-type-selection .type {
  position: relative;
  .icon { color: #fff !important; }
}

.search-type-selection .type .dropdown {
  background-color: #5c6ae0 !important;
  border-radius: 3px 0 0 3px;
  color: #fff !important;
  font-weight: 600 !important;
  height: 48px;
  text-transform: capitalize !important;
  white-space: nowrap;
}

.search-type-selection .type .dropdown ~ .icon {
  pointer-events: none !important;
  position: absolute !important;
  right: 5px !important;
  top: 15px !important;
}

.search-type-selection .type .dropdown button {
  height: 100% !important;
  padding-right: 30px !important;
}

.search-menu {
  margin: 0 0 65px; /* account for fixed height footer */
  max-height: 60vh;
  overflow: auto;
  padding: 0;
}

.search-menu .footer {
  background-color: #fff;
  border-top: 1px solid #e6e7e8;
  bottom: 0;
  padding: 5px;
  position: absolute !important;
  width: 100%;
  z-index: 100;
}

.search-menu .footer .actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.search-menu .footer .actions .active {
  color: #5c6ae0;
}

.search-menu .footer .actions div:first-child {
  max-width: 70%;
}

.search-menu .menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 500px;
  max-width: 300px;
  > div > div { padding: 5px 0 0 !important; }
}

.search-menu .menu-items > div {
  @include flex-wrap-fix(200px);
  margin: 5px 25px !important;
  min-width: 200px !important;
  white-space: nowrap;
}

.search-menu .menu-items label {
  font-size: 14px !important;
  line-height: 14px !important;
  // padding: 3px 10px 3px 50px !important;
  > div { top: 1px !important; }
}

.search-menu .menu-items label svg {
  height: 18px !important;
  width: 18px !important;
}

