.no-post.disabled {
  align-items: center;
  display: flex;
  margin-right: 100px;
  min-height: 150px;
  position: relative;
}

.no-post .empty {
  text-align: center;
  width: 100%;
}

.no-post .empty > div {
  border: 1px solid #9b9b9d;
  color: #9b9b9d;
  margin: 0 auto;
  padding: 15px 35px;
  width: 225px;
}

.no-post .empty a {
  color: #5c6ae0 !important;
  text-decoration: none !important;
}

.replies {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: -35px 5px 0 10px;
  position: relative;

  &:hover > div { border-color: #5c6ae0; }
  &.hidden { display: none; }
}

.replies > div {
  border-left: 2px solid #9b9b9d;
  cursor: pointer;
  max-width: 735px;
  padding: 15px 0 15px 15px;
  width: 100%;
}

.replies > div > div {
  cursor: auto;
  margin: 0 0 15px !important;
  &:last-child { margin: 0 !important; }
}

.replies .replies > div {
  margin-top: -15px;
  width: calc(100% - 15px);
}
