.alerts {
  border-radius: 5px !important;
  overflow: visible;
  padding: 0 0 35px;
  width: 100%;
}

.alerts .card {
  border-radius: 5px !important;
  margin: 0;
  &.loaded >div { box-shadow: 0 1px 6px rgba(0, 0, 0, .12), 0 1px 4px rgba(0, 0, 0, .24) !important; }
  >div { overflow: visible !important; }
}

.alerts .disableRow {
  cursor: not-allowed;
}

.alerts .disableLink {
  pointer-events: none;
}

.alerts .none {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 650px;
  padding: 50px 0;
}

.alerts .none .profile {
  align-items: center;
  display: flex;
  padding: 25px;
}

.alerts .none .profile .dropdown {
  background-color: #e6e7e8 !important;
  border-radius: 5px !important;
  height: 28px !important;
  line-height: 28px !important;
  margin: 0 0 0 15px;
  min-width: 100px;
  width: 50% !important;
  button { display: none !important; }
  button ~ div { display: none !important; }
  span:not(.action) { text-transform: capitalize !important; }
}

.alerts .none .profile .dropdown ~ .action {
  color: #9b9b9d !important;
  pointer-events: none !important;
  right: 35px !important;
  top: 8px !important;
}

.alerts .none .profile .dropdown > div > div {
  color: #9b9b9d !important;
  font-size: 1.6rem !important;
  height: auto !important;
  line-height: initial !important;
  padding: 0 40px 0 10px !important;
  top: 5px !important;
}

.alerts .profile .body {
  color: #1a1b33;
  font-size: 1.8rem;
  >div:nth-child(2) { padding: 10px 0; }
}

.alerts .profile .body .a {
  display: inline-block;
  font-weight: 600;
  padding: 0 5px;
}

.alerts .sort {
  top: -8px !important;
}

.alerts .sort + button {
  left: -10px !important;
  span { color: #000 !important; }
}

.alerts tbody tr:hover .icon {
  opacity: 1;
}

.alerts tbody tr:hover .icon:hover {
  opacity: .6 !important;
}

.alerts .load button {
  background-color: #5c6ae0 !important;
  font-family: 'Montserrat' !important;
  span { color: #fff !important; }
}

.alerts .load circle {
  opacity: 0 !important;
  stroke: #fff !important;
}

.alerts .load.loading circle {
  opacity: 1 !important;
}
