/* stylelint-disable */
.sanctions {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: flex-start;
  margin: auto;
  padding: 0 20px;
  width: 100%;

  .card {
    display: flex;
    flex-direction: column;
    padding: 30px 38px 24px;
  }
}

.search {
  align-items: start;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: start;
  padding: 0;
  width: 100%;

  .card {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 10px 18px 0;
    padding: 12px 18px;
    width: 100%;
  }

  .card .bolded {
    font-weight: 900;
  }

  .card .subsection {
    display: grid;
    gap: 8px;
    /* stylelint-disable unit-whitelist */
    grid-template-columns: repeat(3, 1fr);
    /* stylelint-enable unit-whitelist */
    min-width: 174px;
    padding-left: 20px;
    width: 100%;
  }

  .card .subsectionchain {
    display: grid;
    gap: 8px;
    /* stylelint-disable unit-whitelist */
    grid-template-columns: 1fr 1fr 1fr 2fr;
    /* stylelint-enable unit-whitelist */
    min-width: 174px;
    padding-left: 20px;
    width: 100%;
  }

  .card .text {
    align-self: flex-end;
    color: #9f9e9f;
    flex-grow: 1;
    font-family: 'Montserrat';
    font-size: 16px;
    margin-top: 6px;
    text-align: right;
  }

  .card .num {
    align-self: flex-end;
    color: #3b3a3b;
    flex-grow: 1;
    font-family: 'Rajdhani';
    font-size: 24px;
    font-weight: 200;
    margin-top: 4px;
    text-align: right;
  }

  .card .numchain {
    align-self: flex-end;
    margin-top: 4px;
    text-align: right;
  }

  .card .unknownrisk {
    color: #3b3a3b;
    font-family: 'Rajdhani';
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 4px;
    text-align: right;
  }

  .card .lowrisk {
    color: #31a216;
    font-family: 'Rajdhani';
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 4px;
    text-align: right;
  }

  .card .highrisk {
    color: #d90606;
    font-family: 'Rajdhani';
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 4px;
    text-align: right;
  }
}

.sanctions,
.search {
  .sanctionssection {
    margin-top: 20px;
    width: 100%;
  }

  .form {
    display: flex;
    gap: 14px;
    width: 100%;
  }

  .form .icon {
    align-self: flex-end;
    color: #1a1b33 !important;
  }

  .form .formsubmit {
    align-self: flex-start;
    background-color: #5c6ae0;
    border-radius: 4px;
    border-style: none;
    box-shadow: none;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px !important;
    outline: none;
    padding: 9px 16px;
    text-transform: uppercase;
  }

  .form .formsubmit:hover,
  .form .formsubmit:focus {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.18);
  }

  .form .buttonholder {
    width: 85.167px;
  }

  .insetlabel {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .insetlabel .insetspan {
    background: #fff;
    color: #9f9e9f;
    font-size: 12px;
    margin-left: 14px;
    padding: 0 8px;
    position: absolute;
    top: -8px;
  }

  .insetlabel select,
  .insetlabel input,
  .insetlabel textarea {
    border: 1px solid #c4c2c9;
    border-radius: 4px;
    color: #3b3a3b;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 18px !important;
    outline: none !important;
    padding: 11px 12px 5px;
  }

  .insetlabel select:focus,
  .insetlabel input:focus,
  .insetlabel textarea:focus {
    border-color: #5c6ae0;
  }

  .insetlabel textarea {
    height: 36px !important;
    min-height: 36px;
    resize: vertical;
  }

  .clientname {
    @extend .insetlabel;
    width: 300px;
  }

  .clientname select {
    width: 300px;
  }

  .searchterm {
    @extend .insetlabel;
    flex-grow: 1;
  }

  .searchterm input {
    margin-bottom: 8px;
  }

  .fuzzysearch {
    @extend .insetlabel;
    border: 1px solid #c4c2c9;
    border-radius: 4px;
    color: #3b3a3b;
    font-size: 14px;
    line-height: 18px !important;
    outline: none !important;
    padding: 8px 12px 2px;
    width: 120px;
  }

  .fuzzysearch span {
    align-self: center;
  }

  .fuzzysearch label {
    margin-left: 0;
  }

  .pastsearchfilter {
    @extend .insetlabel;
    width: 200px;
  }

  .pastsearchfilter input,
  .pastsearchfilter select {
    height: 37px;
    width: 200px;
  }

  .notesfilter {
    @extend .insetlabel;
    width: 400px;
  }

  .notesfilter input {
    height: 37px;
    width: 400px;
  }

  .notesfilter select {
    height: 37px;
    width: 400px;
  }

  .savedcellhead {
    color: #767577;
    cursor: default;
    font-family: 'Montserrat';
    padding: 12px 12px 6px !important;
  }

  .savedcell {
    cursor: default;
    overflow: visible;
    overflow-wrap: anywhere !important;
    padding: 12px !important;
    text-overflow: initial;
    white-space: normal;
  }

  .linkcell {
    cursor: pointer;
    overflow: visible;
    overflow-wrap: normal !important;
    padding: 12px !important;
    text-overflow: initial;
    white-space: normal;
  }

  .linkcell a {
    color: #767577;
    cursor: pointer;
  }

  .linkcell a:hover,
  .linkcell a:active {
    color: rgb(255, 165, 0) !important;
  }

  .savedpaginate {
    align-items: center;
    align-self: flex-end;
    display: flex;
    margin-top: 12px;
  }

  .filters {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .searchstatus {
    border: 1px solid;
    border-radius: 2px;
    font-family: 'Rajdhani';
    font-size: 16px;
    padding: 2px 8px;
  }

  .draftstatus {
    @extend .searchstatus;
    border-color: #176ba6;
    color: #176ba6;
  }

  .finalizedstatus {
    @extend .searchstatus;
    border-color: #5c6ae0;
    color: #5c6ae0;
  }

  .submittedstatus {
    @extend .searchstatus;
    border-color: #31a216;
    color: #31a216;
  }

  .nullstatus {
    @extend .searchstatus;
    border-color: #767577;
    color: #767577;
  }

  // Search Results Styles ---------------------------------------------------------------------------------

  .title {
    font-family: 'Rajdhani';
    font-size: 24px;
  }

  .medtitle {
    color: #3b3a3b;
    font-family: 'Rajdhani';
    font-size: 20px;
  }

  .resulttitle {
    color: #1b1a34;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 4px;
    margin-top: 12px;
    text-transform: uppercase;
  }

  .subtitle {
    color: #3b3a3b;
    font-family: 'Rajdhani';
    font-size: 16px;
    text-transform: uppercase;
  }

  .accordiontitle {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .listtitle {
    align-items: flex-end;
    display: flex;
    margin-bottom: 8px;
  }

  .lighttext {
    color: #767577;
  }

  .termtitle {
    align-items: center;
    display: flex;
    gap: 12px;
  }

  .termtitle a {
    color: #9f9e9f;
    font-size: 12px;
  }

  .resultdownload {
    background: none;
    border-style: none;
    box-shadow: none;
    color: #9e9e9e;
    cursor: pointer;
    height: 24px;
    padding: 0;
    width: 24px;
  }

  .statusedit {
    align-items: center;
    display: flex;
  }

  .statusedit button {
    align-self: center;
    margin-left: 4px;
  }

  .statusselect {
    margin-right: 4px;
    padding: 1px;
  }

  .removebutton {
    background: none;
    border-style: none;
    box-shadow: none;
    color: #9f9e9f;
    outline: none;
  }

  .searchcomponent {
    align-items: start;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    justify-content: start;
    margin: 10px 20px 10px 0;
  }

  .notesreports {
    display: flex;
    margin-bottom: 28px;
    width: 100%;
  }

  .notesreports .notes,
  .notesreports .reports {
    width: 50%;
  }

  .notesreports .notes .notescontent,
  .notesreports .reports .notescontent {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-height: 30px;
    overflow: auto;
  }

  .notesreports .notes .notescontent .addnewobject,
  .notesreports .reports .notescontent .addnewobject {
    align-self: flex-end;
  }

  .notesreports .notes .reportscontent,
  .notesreports .reports .reportscontent {
    max-height: 500px;
    min-height: 81px;
    overflow: auto;
  }

  .notesreports .notes {
    margin-right: 20px;
  }

  .resultaccordion {
    box-shadow: none !important;
    flex-direction: column !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
  }

  .resultaccordion .accordionsummary {
    align-items: flex-start !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .resultaccordion .accordionsummarycontent {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .resultaccordion .accordiondetails {
    flex-direction: column;
    padding: 0 !important;
  }

  .resultaccordion .chainalysisdetails {
    display: flex;
  }

  .resultaccordion .chainalysisdetails div {
    margin-right: 36px;
  }

  .searchinfo {
    column-gap: 30px;
    display: grid;
    grid-template-columns: auto auto;
    min-width: 316px;
    row-gap: 8px;
  }

  .noresults {
    align-items: center;
    border: 1px solid #31a216;
    border-radius: 2px;
    color: #31a216;
    display: flex;
    font-family: 'Rajdhani';
    font-size: 18px;
    font-weight: 300;
    margin-left: 8px;
    padding: 2px 8px;
  }

  .someresults {
    align-items: center;
    border: 1px solid #d90606;
    border-radius: 2px;
    color: #d90606;
    display: flex;
    font-family: 'Rajdhani';
    font-size: 18px;
    font-weight: 300;
    margin-left: 8px;
    padding: 2px 8px;
  }

  .offlineresults {
    align-items: center;
    border: 1px solid #9f9e9f;
    border-radius: 2px;
    color: #9f9e9f;
    display: flex;
    font-family: 'Rajdhani';
    font-size: 18px;
    font-weight: 300;
    margin-left: 8px;
    padding: 2px 8px;
  }

  .resultsfor {
    // width: calc(100% - 20px);
    width: 100%;
  }

  .noresultsmessage {
    width: 100%;
  }

  .resultunderline {
    background-color: #d90606;
    border-radius: 2px;
    height: 4px;
    margin: 0 0 12px;
    width: 100%;
  }

  .resultunderlinechain {
    background-color: #d90606;
    border-radius: 2px;
    height: 4px;
    margin: 12px 0;
    width: 100%;
  }

  .offlineunderline {
    background-color: #9f9e9f;
    border-radius: 2px;
    height: 4px;
    margin: 12px 0;
    width: 100%;
  }

  .noresultunderline {
    background-color: #31a216;
    border-radius: 2px;
    height: 4px;
    margin: 12px 0;
    width: 100%;
  }

  .termunderlineresults {
    background-color: #d90606;
    border-radius: 1px;
    height: 2px;
    margin: 2px 0 6px;
    width: 100%;
  }

  .termunderlineclear {
    background-color: #31a216;
    border-radius: 1px;
    height: 2px;
    margin: 2px 0 6px;
    width: 100%;
  }

  .detailsgrid {
    display: grid;
    /* stylelint-disable unit-whitelist */
    grid-template-columns: repeat(3, 1fr);
    /* stylelint-enable unit-whitelist */
    padding-right: 60px;
    width: 100%;
  }

  .detailsgrid ul {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .detailsgrid ul li {
    display: inline;
    margin: 0;
    padding: 0;
  }

  .detailsgrid ul li::after {
    content: ', ';
  }

  .detailsgrid ul li:last-child::after {
    content: '';
  }

  .searchgrid {
    display: grid;
    /* stylelint-disable unit-whitelist */
    grid-template-columns: 1fr 1.75fr;
    /* stylelint-enable unit-whitelist */
    width: 100% !important;
  }

  .searchsection {
    margin: 0 0 12px;
    width: 100%;
  }

  .searchterms {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .urls {
    margin-top: 8px;
  }

  .info {
    align-items: start;
    display: flex;
    justify-content: start;
  }

  .resultssection {
    margin-bottom: 60px;
  }

  .resultstable tr:nth-child(odd) {
    background-color: #f6f5f7;
  }

  .resultstable tr:nth-child(odd) th {
    background: #fff;
    border-style: none;
    color: #767577;
    font-family: 'Montserrat';
    padding-bottom: 2px;
  }

  .resultstable td {
    border-style: none;
    cursor: default;
    max-width: none;
  }

  .resultstable td:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  .resultstable td:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }

  .resultstable td a {
    color: #3b3a3b;
    cursor: pointer;
    font-weight: 700;
  }

  .resultstable td svg {
    font-size: 16px;
    margin-bottom: -3px;
  }

  .titleedit {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .reportformwrap {
    display: flex;
    flex-direction: column;
  }

  .reportform {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  .reportform .reportinput {
    border: 1px solid #c4c2c9;
    border-radius: 2px;
    margin-bottom: 6px;
    outline: none;
    padding: 6px 12px;
    width: 100%;
  }

  .reportform .titleedit {
    align-items: flex-end;
    margin-bottom: 6px;
  }

  .reportform .addnewobject {
    align-self: flex-end;
  }

  .sectionsubmit {
    background: #5c6ae0;
    border-radius: 2px;
    border-style: none;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    outline: none;
    padding: 3px 12px;
    width: auto;
  }

  .addnewobject {
    align-self: flex-end;
    background: none;
    border-style: none;
    box-shadow: none;
    color: #1b1a34;
    cursor: pointer;
    outline: none;
    width: auto;
  }

  .editbutton {
    align-self: flex-end;
    background: none;
    border-style: none;
    box-shadow: none;
    color: #767577;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    width: auto;
  }

  .editbutton svg {
    font-size: 14px;
    margin-bottom: -2px;
    margin-right: 4px;
  }

  .note {
    background-color: #f6f5f7;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-top: 2px;
    padding: 6px 12px;
  }

  .noteinput {
    border: 1px solid #9f9e9f;
    border-radius: 4px;
    color: #3b3a3b;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 18px !important;
    outline: none !important;
    padding: 11px 12px 5px;
    resize: vertical;
    width: 100%;
  }

  .noteinput:focus {
    border-color: #5c6ae0;
  }

  // Reports styles ---------------------------------------------------------------------------------

  .reportinfo {
    align-items: center;
    display: flex;
  }

  .noresultscheck,
  .resultswarn,
  .offlinecloud {
    font-size: 20px;
    height: 18px;
    margin-right: 6px;
    width: 18px;
  }

  .resultswarn {
    color: #d90606;
  }

  .noresultscheck {
    color: #31a216;
  }

  .offlinecloud {
    color: #9f9e9f;
  }

  .searchtermsgrid {
    width: 100%;
  }

  .runnewreport {
    align-self: flex-end;
    background: none;
    border-style: none;
    box-shadow: none;
    color: #9e9e9e;
    cursor: pointer;
    display: flex;
    float: right;
    font-family: 'Open Sans';
    font-size: 16px;
    padding: 0;
  }

  .runnewreport svg {
    margin-right: 8px;
  }

  .allresultdownload {
    @extend .runnewreport;
    margin-top: 0;
  }

  .gridlink {
    color: #5c6ae0;
    font-weight: 800;
  }

  .noresultscard {
    width: 400px;
  }
}

.allresultdownload {
  align-self: flex-end;
  background: none;
  border-style: none;
  box-shadow: none;
  color: #9e9e9e;
  cursor: pointer;
  display: flex;
  float: right;
  font-family: 'Open Sans';
  font-size: 16px;
  margin-top: 0;
  padding: 0;
  position: absolute;
  right: 20px;

  svg {
    margin-right: 8px;
  }
}
