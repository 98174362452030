.thread {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  transition: width .5s;
  width: 100%;
}

.thread.pull {
  width: 600px;
}

.thread .header {
  margin: 0 0 35px;
}

.thread .title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
}

.thread .title .h1 {
  max-width: 700px;
}

.empty {
  background: #fff;
  border: 1px #000 solid;
  height: 100px;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 300px;
  z-index: 1000;
}

.empty p {
  padding-top: 25px;
  text-align: center;
}

.thread .title .icon {
  color: #000 !important;
  margin: 0 0 0 -30px;
  width: 30px;
}

.thread.compact {
  flex-direction: unset !important;

  &.pull { width: calc(100% - 310px) !important; }
  > div { width: 100%; }
  .header .title .icon { margin-left: 0 !important; }
}
