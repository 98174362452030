.media {
  align-items: center;
  color: #303137;
  direction: ltr;
  display: block;
  font-size: 1.6rem;
  margin: 5px 0;
  padding: 0;
}

.media .file.details:not(.unavailable) {
  background-color: #fff;
  border: 1px solid #5c6ae0;
  border-radius: 5px;
  color: #4f569c;
  padding: 2px 5px !important;
  .icon { color: #5c6ae0 !important; }
}

.media .attach {
  color: #000 !important;
  font-size: 2rem !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 2px;
}

.media .content .actions {
  display: flex;
  padding: 5px 0;
}

.media .content .actions .report {
  bottom: 0;
  position: absolute;
}

.media .download {
  cursor: default;
}

.media .button {
  border-radius: 5px !important;
  box-shadow: none !important;
}

.media .button.disabled button {
  background-color: #e6e7e8 !important;
  pointer-events: none;
}

.media .button button {
  background-color: #5c6ae0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 25px !important;
  line-height: 25px !important;
}

.media .button button span {
  color: #fff !important;
}

.media .button button:hover {
  bottom: 1px;
  box-shadow: 0 3px 10px rgba(0, 0, 0 , .16), 0 3px 10px rgba(0, 0, 0, .23) !important;
}

.media .button button > div > div {
  font-family: 'Montserrat' !important;
  height: 25px !important;
}

.media .content {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.media .content .mime {
  align-items: center;
  background-color: #9b9b9d;
  border: 2px solid #9b9b9d;
  color: #fff;
  display: flex;
  padding: 5px;
  text-transform: uppercase;
}

.media .content .details {
  display: block;
  flex-grow: 1;
}

.media .content .title,
.media .unavailable {
  font-size: 1.6rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.media .content .size {
  color: #79797b;
  font-size: 1.4rem;
}

.media .content .safe.message {
  margin-left: auto;
}

.media .expanded .preview {
  align-items: center;
  background-color: #c3c3c7;
  display: flex;
  justify-content: center;
  min-width: 100px;
}

.media .expanded .preview img {
  cursor: pointer;
  width: 100%;
}

.media .expanded .document {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  // &.opacity { opacity: .8; }
}

.media .expanded .document .icon {
  color: #5c6ae0 !important;
  font-size: 4.8rem !important;
}

.media .expanded .actions {
  display: flex;
  flex-direction: column;
}

.media .expanded .document .extension {
  text-align: center;
}

.media .expanded .toggle-actions {
  max-width: 200px;
  // position: absolute;
  text-align: center;
}

.media .expanded .toggle-actions .warning {
  font-size: 1.2rem;
  padding: 0 10px 10px;
}

.media .expanded .actions .button {
  margin-bottom: 10px;
  min-width: 40px !important;
}

.media .embed {
  display: flex;
  flex-direction: row;
  position: relative;
}

.media .embed .container {
  display: flex;
  justify-content: center;
  max-height: 300px;
  max-width: 100%;
  min-width: 300px;
  position: relative;
  width: 100%;
}

.media .embed .container * {
  max-height: 300px;
  max-width: 100%;
}

.media img {
  cursor: pointer;
}

.media .inline {
  align-items: center;
  display: flex;
  height: 35px;
  justify-content: flex-start;
  >div:first-child { padding: 0 15px 0 0; }
}

.media .table {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 3px 0;
  .top { align-self: flex-start; }
}

.media .table >div:nth-child(2) {
  word-break: break-word;
}

.actions .active span {
  color: #5c6ae0 !important;
}
