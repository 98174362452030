.containers {
  display: flex;
  flex-direction: column;
}

.containers .sections {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containers .section {
  display: flex;
}

.curation {
  display: inline-block;
  margin: 8px auto 0;
}

.curation .button {
  border-radius: 5px !important;
  color: #1a1b33 !important;
  height: 25px !important;
  line-height: 25px !important;
}

.curation .button.active {
  background-color: #1a1b33 !important;
  color: #fff !important;
}
